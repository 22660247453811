import { Button, Title, DEFAULT_THEME } from "@tigris/mesokit";
import { ThemeSelector } from "./ThemeSelector";
import { motion, Variants } from "framer-motion";
import { useOnboarding } from "../hooks/useOnboarding";
import { Posthog, TelemetryEvents } from "@tigris/common";

const variants: Variants = {
  initial: { y: 400, opacity: 1 },
  animate: {
    y: 0,
    opacity: 1,
    transition: { ease: "circOut", duration: 0.33 },
  },
  exit: {
    y: 400,
    opacity: 1,
    transition: { ease: "easeIn", duration: 0.33 },
  },
};

export const ThemeBottomCard = ({
  onCloseThemePicker,
}: {
  onCloseThemePicker: () => void;
}) => {
  const { user } = useOnboarding();

  return (
    <div className="inset-t-10 rounded-ts-card absolute inset-0 flex h-full w-full flex-col overflow-hidden bg-transparent">
      <motion.div
        key="theme-picker"
        className="absolute right-1 bottom-1 left-1 z-10 flex flex-col gap-2 rounded-[1.75rem] bg-neutral-50 p-4 shadow-xl dark:bg-neutral-700 dark:text-white"
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <Title.Small className="font-bold">Select a Theme</Title.Small>{" "}
        <ThemeSelector />
        <div className="mt-2">
          <Button
            onClick={() => {
              Posthog.capture(TelemetryEvents.onboardingThemePickerClose);
              onCloseThemePicker();
            }}
            themeOverride={
              user.theme === DEFAULT_THEME ? undefined : "transfer-button"
            }
          >
            Done
          </Button>
        </div>
      </motion.div>
    </div>
  );
};
