import { Variants, motion } from "framer-motion";
import { spring } from "@src/utils/animation";
import SupportMenu from "./SupportMenu";

const headerAnimationVariants: Variants = {
  initial: { opacity: 0, y: -16, scale: 0.9 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { ...spring, delay: 0.25 },
  },
};

export default function Header() {
  return (
    <motion.header
      key="SupportMenu:Header"
      variants={headerAnimationVariants}
      initial="initial"
      animate="animate"
      className="absolute top-0 left-0 z-20 w-full px-4 pt-4 pb-2 text-black dark:text-white"
    >
      <SupportMenu />
    </motion.header>
  );
}
