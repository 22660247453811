import { MesoKitContext, useSafeLocalStorage } from "@tigris/mesokit";
import { toast } from "sonner";
import { ApiContextProvider } from "../src/contexts/ApiContextProvider";
import { OnboardingContextProvider } from "../src/contexts/OnboardingContextProvider";
import { RouterContextProvider } from "../src/contexts/RouterContextProvider";
import { SardineRiskSession } from "../src/contexts/SardineRiskSession";
import { DevControls } from "../src/dev/DevControls";
import { Layout } from "../src/Layout";
import {
  OnboardingAppRenderContext,
  OnboardingConfiguration,
  OnboardingContextValue,
  Routes,
  User,
  Asset,
} from "../src/types";
import { routes } from "../src/routes";
import { MesoPosthog, MesoSentry } from "@tigris/common";
import { getNextOnboardingStep } from "../src/utils/nextOnboardingStep";

type OnboardingAppProps = Required<OnboardingConfiguration> & {
  onReturnToTransfer: OnboardingContextValue["returnToTransfer"];
  telemetry: {
    Sentry: MesoSentry;
    Posthog: MesoPosthog;
  };
  user?: Partial<User>;
  sourceAmount?: string;
  destinationAsset?: Asset;
};

// This is the onboarding app that runs in a standalone integration
export const OnboardingApp = ({
  network,
  partner,
  session,
  walletAddress,
  telemetry,
  onReturnToTransfer,
  supportedPaymentMethods,
  profileStatus,
  user,
  sourceAmount,
  destinationAsset,
}: OnboardingAppProps) => {
  const passkeyOnboardingEnabled = session.passkeyOnboardingEnabled;
  const hasPasskey = useSafeLocalStorage("meso:hasPasskey", false)[0];

  const transferEligibleForScamWarning =
    Number(sourceAmount ?? 0) >= 497 &&
    (destinationAsset === Asset.BTC || destinationAsset === Asset.ETH);

  const next = getNextOnboardingStep({
    profileStatus,
    supportedPaymentMethods,
    user,
    passkeyOnboardingEnabled,
    hasPasskey,
    transferEligibleForScamWarning,
  });

  let initialRoute = { pathname: Routes.Root };
  if (next.isOk()) {
    initialRoute = { pathname: next.value };
  }

  return (
    <RouterContextProvider
      routes={routes}
      initialRoute={initialRoute}
      renderContext={OnboardingAppRenderContext.EMBEDDED_IN_TRANSFER}
    >
      <MesoKitContext.Provider
        value={{
          sentry: telemetry.Sentry,
          posthog: telemetry.Posthog,
          toast,
        }}
      >
        <ApiContextProvider providedSession={session}>
          <OnboardingContextProvider
            network={network}
            partner={partner}
            walletAddress={walletAddress}
            onReturnToTransfer={onReturnToTransfer}
            supportedPaymentMethods={supportedPaymentMethods}
            profileStatus={profileStatus}
            renderContext={OnboardingAppRenderContext.EMBEDDED_IN_TRANSFER}
            transferEligibleForScamWarning={transferEligibleForScamWarning}
          >
            <SardineRiskSession flow="onboarding">
              <Layout />
            </SardineRiskSession>
            <DevControls />
          </OnboardingContextProvider>
        </ApiContextProvider>
      </MesoKitContext.Provider>
    </RouterContextProvider>
  );
};
