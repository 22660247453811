import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, FormEventHandler, useCallback, useState } from "react";
import { Text, Button, spring } from "@tigris/mesokit";
import { getNextOnboardingStep } from "../utils/nextOnboardingStep";
import { faFlag, faLightEmergencyOn } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "sonner";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { useRouter } from "../hooks/useRouter";
import { ScamWarningKind } from "../generated/sdk";
import { Posthog, TelemetryEvents } from "@tigris/common";

const TOAST_ID = "Scam Warning";
const FLAG_ICON_CLASS = "w-6 w-6 pr-1 text-danger";

export const ScamWarning = () => {
  const { navigate } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { user, configuration } = useOnboarding();
  const {
    api: { resolveAcknowledgeScamWarning },
  } = useApi();

  const handleAcknowledgeScamWarning = useCallback<FormEventHandler>(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);

      const acknowledgeScamWarningResult = await resolveAcknowledgeScamWarning({
        input: { kind: ScamWarningKind.WISER_USER },
      });

      if (acknowledgeScamWarningResult.isErr()) {
        toast.error(acknowledgeScamWarningResult.error, { id: TOAST_ID });
        setIsLoading(false);
        return;
      }

      Posthog.capture(TelemetryEvents.onboardingWiserUserScamWarning);

      const nextStep = getNextOnboardingStep({
        profileStatus: acknowledgeScamWarningResult.value,
        supportedPaymentMethods: configuration.supportedPaymentMethods,
        user,
      });

      if (nextStep.isErr()) {
        toast.error(nextStep.error, { id: TOAST_ID });
        return;
      }

      navigate(nextStep.value);
    },
    [configuration, navigate, resolveAcknowledgeScamWarning, user],
  );

  return (
    <AnimationContainer>
      <form
        id="OnboardingScamWarning"
        name="OnboardingScamWarning"
        onSubmit={handleAcknowledgeScamWarning}
        className="onboarding-inner-content"
      >
        <Heading
          title="Don't get scammed"
          subtitle="Once you send crypto, it can’t be un-sent. Here’s how to protect yourself."
        />

        <div className="flex w-full flex-col gap-2 rounded-xl border border-solid border-neutral-800/5 p-4 shadow-md dark:border-gray-600">
          <div className="flex flex-col gap-1.5 border-b border-solid border-neutral-800/5 pb-2 dark:border-gray-600">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faFlag} className={FLAG_ICON_CLASS} />
              <Text className="font-bold">Fake Investment Advice</Text>
            </div>
            <Text className="opacity-70">
              Be cautious if someone online is pressuring you to invest in
              crypto.
            </Text>
          </div>
          <div className="flex flex-col gap-1.5 border-b border-solid border-neutral-800/5 pb-2 dark:border-gray-600">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faFlag} className={FLAG_ICON_CLASS} />
              <Text className="font-bold">Fake Job Offers</Text>
            </div>
            <Text className="opacity-70">
              Never send crypto to someone claiming to be an employer.
            </Text>
          </div>
          <div className="flex flex-col gap-1.5">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faFlag} className={FLAG_ICON_CLASS} />
              <Text className="font-bold">Fake Tech Support</Text>
            </div>
            <Text className="opacity-70">
              Don’t send crypto to anyone claiming to be calling from tech
              support.
            </Text>
          </div>
        </div>

        <AnimatePresence>
          <motion.div
            className="mt-auto flex h-auto w-full items-center gap-2 rounded-2xl border border-neutral-100 p-4 shadow-xl dark:border-neutral-700"
            initial={{ opacity: 0, y: 24 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { ...spring, delay: 0.5 },
            }}
          >
            <FontAwesomeIcon
              icon={faLightEmergencyOn}
              size="lg"
              className="text-warning"
            />
            <div className="text-sm text-neutral-800 dark:text-white">
              <span>Too good to be true? </span>
              <span className="font-bold">It&apos;s probably a scam.</span>
            </div>
          </motion.div>
        </AnimatePresence>

        <div className="onboarding-footer mt-0">
          <Button
            disabled={isLoading}
            key="OnboardingScamWarning:button"
            type="submit"
          >
            I Understand
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
