import { motionVariants, spring } from "@src/utils/animation";
import { Variants, motion } from "framer-motion";
import { Button, Text } from "@tigris/mesokit";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "@src/utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useContext, useEffect } from "react";
import { AppContext } from "@src/contexts/AppContext";
import { AuthenticationStrategy, IntegrationMode } from "@src/types";
import { twMerge } from "tailwind-merge";
import { useOnboarding } from "@src/hooks/useOnboarding";

const animationVariants: Variants = {
  initial: { opacity: 0, y: 24 },
  exit: { opacity: 0, y: 24, transition: spring },
  animate: {
    opacity: 1,
    y: 0,
    transition: { ...spring, delay: 0.25 },
  },
};

export const LandingSheetLoginLanding = () => {
  const {
    isEditingAmount,
    session,
    configuration: { authenticationStrategy },
    mode,
    hasContextError,
    hasPasskey,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { initializeOnboarding } = useOnboarding();

  useEffect(() => {
    const skipWalletVerification =
      mode === IntegrationMode.STANDALONE ||
      authenticationStrategy ===
        AuthenticationStrategy.BYPASS_WALLET_VERIFICATION;

    if (skipWalletVerification && (session?.isReturningUser || hasPasskey)) {
      navigate({ pathname: Routes.LandingSheetLoginEntry, search });
    }
  }, [
    authenticationStrategy,
    hasPasskey,
    mode,
    navigate,
    search,
    session?.isReturningUser,
  ]);

  const handleOnboardingInitialization = useCallback(() => {
    initializeOnboarding();
  }, [initializeOnboarding]);

  return (
    <motion.div key="LandingSheetLoginLanding" {...motionVariants.fadeInDown}>
      <div className="flex w-full flex-col gap-3">
        <motion.div
          variants={animationVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          className={twMerge(
            "group w-full cursor-pointer text-center font-medium",
            isEditingAmount && "cursor-not-allowed",
          )}
          data-testid="loginButton"
          onClick={() => {
            if (!hasContextError) {
              navigate({ pathname: Routes.LandingSheetLoginEntry, search });
            }
          }}
        >
          <Text
            className={twMerge(
              "opacity-50",
              hasContextError && "cursor-not-allowed",
            )}
          >
            Used Meso before? Log in{" "}
            <span
              className={twMerge(
                "inline-flex transition-transform",
                !isEditingAmount && "group-hover:translate-x-0.5",
              )}
            >
              {"  "}→
            </span>
          </Text>
        </motion.div>
        <Button
          onClick={handleOnboardingInitialization}
          className="w-full"
          data-testid="signupButton"
          disabled={isEditingAmount || hasContextError}
          themeOverride="transfer-button"
        >
          <div className="flex items-center justify-center gap-2">
            <FontAwesomeIcon icon={faCreditCard} />
            Connect Debit Card
          </div>
        </Button>
      </div>
    </motion.div>
  );
};
