import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CountryCodeAlpha2,
  OnboardingAppRenderContext,
  OnboardingContextValue,
  ReturnToTransferReason,
  User,
} from "../types";
import { OnboardingContext } from "./OnboardingContext";
import { useApi } from "../hooks/useApi";
import {
  DEFAULT_THEME,
  isValidTheme,
  useCookieConsent,
  useSafeLocalStorage,
} from "@tigris/mesokit";
import { Posthog, updateConsent } from "@tigris/common";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";

export type OnboardingContextProviderProps =
  OnboardingContextValue["configuration"] & {
    /**
     * A callback to take the user back to the Transfer window (if available). This is dispatched when onboarding resolves successfully, user activation fails, the user needs to go back to login, or if the user wishes to cancel the session.
     */
    onReturnToTransfer: (reason: ReturnToTransferReason) => void;
    /**
     * The mode the Onboarding application is running in.
     *
     * @default BREAKOUT_FROM_TRANSFER
     */
    renderContext?: OnboardingContextValue["renderContext"];

    transferEligibleForScamWarning: boolean;
  };

export const OnboardingContextProvider = ({
  children,
  network,
  walletAddress,
  partner,
  onReturnToTransfer,
  supportedPaymentMethods,
  profileStatus,
  renderContext = OnboardingAppRenderContext.BREAKOUT_FROM_TRANSFER,
  transferEligibleForScamWarning,
}: PropsWithChildren<OnboardingContextProviderProps>) => {
  const { session, api } = useApi();
  const onSavePreferences = useCallback(
    async (preferences: string) => {
      return await api.resolveUpdateDataConsent({
        input: { dataConsent: preferences },
      });
    },
    [api],
  );
  const cookieConsent = useCookieConsent({
    property: "onboarding",
    onSavePreferences,
  });
  const [user, setUser] = useState<User>(() => {
    let residenceCountry = undefined;
    if (
      profileStatus &&
      profileStatus.residenceCountry &&
      Object.values(CountryCodeAlpha2).includes(
        profileStatus.residenceCountry as CountryCodeAlpha2,
      )
    ) {
      residenceCountry = profileStatus.residenceCountry as CountryCodeAlpha2;
    }

    // If residence country cannot be resolved and we have the int'l feature flag off, default the user to US
    if (!residenceCountry && !session?.euEnabled) {
      residenceCountry = CountryCodeAlpha2.US;
    }

    return {
      theme: "default",
      residenceCountry,
    };
  });

  const updateUser = useCallback<OnboardingContextValue["updateUser"]>(
    (updatedUser: Partial<User>) => {
      if (updatedUser.theme) {
        const newTheme = isValidTheme(updatedUser.theme)
          ? updatedUser.theme
          : DEFAULT_THEME;

        const currentThemeClasses = Array.from(
          document.documentElement.classList,
        ).filter((className) => className.startsWith("theme-"));

        // Side effects!
        document.documentElement.classList.remove(...currentThemeClasses);

        document.documentElement.classList.add(
          `theme-${newTheme ?? "default"}`,
        );

        updatedUser.theme = newTheme;
      }

      let mergedUser: User;
      setUser((prevUser) => {
        mergedUser = {
          ...prevUser,
          ...updatedUser,
        };
        return mergedUser;
      });

      return mergedUser!;
    },
    [],
  );

  const [hasPasskey, setHasPasskey] = useSafeLocalStorage(
    "meso:hasPasskey",
    false,
  );

  useEffect(
    () => updateConsent(cookieConsent.preferences.performance),
    [cookieConsent.preferences.performance],
  );

  // Once the user has a Meso ID, identify them in Posthog
  useEffect(() => {
    if (user.id) Posthog.identify(user.id);
  }, [user.id]);

  const appReady = useMemo(() => session !== undefined, [session]);
  const { CookieDialog } = cookieConsent;

  const contextValue = useMemo<OnboardingContextValue>(
    () => ({
      user,
      appReady,
      updateUser,
      configuration: {
        network,
        walletAddress,
        partner,
        supportedPaymentMethods,
        profileStatus,
      },
      cookieConsent,
      returnToTransfer: onReturnToTransfer,
      renderContext,
      browserSupportsWebAuthn: browserSupportsWebAuthn(),
      hasPasskey,
      setHasPasskey,
      api,
      transferEligibleForScamWarning,
    }),
    [
      appReady,
      cookieConsent,
      network,
      onReturnToTransfer,
      partner,
      profileStatus,
      renderContext,
      supportedPaymentMethods,
      updateUser,
      user,
      walletAddress,
      hasPasskey,
      setHasPasskey,
      api,
      transferEligibleForScamWarning,
    ],
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
      <CookieDialog />
    </OnboardingContext.Provider>
  );
};
