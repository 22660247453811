import { useCallback, useMemo, useRef, useState } from "react";
import { FullName, FullNameProps, FullNameValue } from "../components/FullName";

type UseFullNameHook = {
  /** Whether both first and last name are valid. */
  isValid: boolean;
  /** The `FullName` component to render to the screen. */
  FullName: (props: Pick<FullNameProps, "disabled">) => React.JSX.Element;
  /** Fetch the current value of first/last name. */
  getFullName: () => FullNameValue;
};

/**
 * A helper for using the `FullName` component.
 *
 * Using this hook, an integrator can query the value of first/last name at any time as well as receive real-time updates on validation status.
 */
export const useFullName = (
  /** The initial value of first/last name. */
  props?: FullNameProps["initialValue"],
): UseFullNameHook => {
  const initialValue = useRef({
    firstName: props?.firstName ?? "",
    lastName: props?.lastName ?? "",
  });
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState<FullNameValue>(initialValue.current);
  const getFullName = useCallback(() => value, [value]);

  const handleChange = useCallback<FullNameProps["onChange"]>(
    (value, isValid) => {
      setValue(value);
      setIsValid(isValid);
    },
    [],
  );

  const Comp = useMemo(
    () =>
      ({ disabled }: Pick<FullNameProps, "disabled">) => (
        <FullName
          disabled={disabled}
          initialValue={initialValue.current}
          onChange={handleChange}
        />
      ),
    [handleChange],
  );

  return useMemo(
    () => ({
      isValid,
      FullName: Comp,
      getFullName,
    }),
    [Comp, getFullName, isValid],
  );
};
