import {
  AddWalletMutation,
  AddWalletMutationVariables,
  AnonQuoteQuery,
  AnonQuoteQueryVariables,
  CheckTransferQuery,
  CheckTransferQueryVariables,
  ExecuteTransferMutation,
  ExecuteTransferMutationVariables,
  LoginMessageQuery,
  LoginMessageQueryVariables,
  LoginWithEmailAndPasswordMutationVariables,
  LookupUserMutation,
  MutationPerformWalletLoginArgs,
  NewSessionMutation,
  NewSessionMutationVariables,
  PartnerQuery,
  QuoteQuery,
  QuoteQueryVariables,
  RiskSessionQuery,
  Sdk,
  Send2FaCodeMutationVariables,
  Verify2FaMutationVariables,
  CreateCashOutQuoteMutationVariables,
  CreateAnonCashOutQuoteMutationVariables,
  MutationCompletePasskeyRegistrationArgs,
  MutationCompletePasskeyLoginArgs,
  LoginFragment,
  PasskeyCeremony,
  PerformUserActivationMutationVariables,
  ResolveUserActivationStatusQueryVariables,
  PerformUserActivationMutation,
  ResolveUserActivationStatusQuery,
  ThreeDomainSecureSession,
  CreateThreeDomainSecureSessionMutationVariables,
  SetThreeDomainSecureSessionMethodCompletionMutationVariables,
  SubmitUserFeedbackMutationVariables,
  ApplePayPaymentSession,
  RequestApplePayPaymentSessionMutationVariables,
  AddPaymentCardMutationVariables,
  AddPaymentCardMutation,
  RemoveFiatInstrumentMutationVariables,
  RemoveFiatInstrumentMutation,
  UpdateDataConsentMutationVariables,
} from "./generated/sdk";
import { AnonCashOutQuote, AuthorizedCashOutQuote } from "./types";
import { Result, err, ok } from "neverthrow";
import { ClientError } from "graphql-request";
import {
  AddPaymentCardErrorCode,
  ErrorMessages,
  isAddPaymentCardErrorCode,
} from "@tigris/mesokit";
import { isAuthFactorError, Sentry } from "@tigris/common";

export type ResolveUserResult = Result<
  Extract<LookupUserMutation["user"], { __typename: "User" }>,
  string
>;

/* Wallet specific login response, can either be LoginFragment if successful, or
 * prompting for email/password in case of login_failed server response,
 * signaling address was unparsable */
export type LoginWithWalletSuccess =
  | LoginFragment
  | { loginWithEmailAndPassword: true };

export type ResolveActivateUserResult = Result<
  Extract<
    PerformUserActivationMutation["activateUser"],
    { __typename: "UserActivation" }
  >,
  string
>;

export type ResolveVerify2FAResult = Result<true, string>;

export type ResolveUserActivationStatusResult = Result<
  Extract<
    ResolveUserActivationStatusQuery["userActivation"],
    { __typename: "UserActivation" }
  >,
  string
>;

export type ResolveCheckTransferResult = Result<
  Extract<CheckTransferQuery["transfer"], { __typename: "Transfer" }>,
  string
>;

export type QuoteError = {
  message: string;
  isLimitError: boolean;
  code?:
    | QuoteLimitErrorCode
    | QuoteNegativeErrorCode
    | QuotePresentmentUnavailableCode;
};

export type ResolveQuoteResult = Result<
  Extract<QuoteQuery["quote"], { __typename: "Quote" }>,
  QuoteError
>;

export type ResolveLoginMessageResult = Result<
  Extract<LoginMessageQuery["loginMessage"], { __typename: "LoginMessage" }>,
  string
>;

export type ResolveRiskSessionResult = Result<
  Extract<RiskSessionQuery["riskSession"], { __typename: "RiskSession" }>,
  string
>;

export type ResolveSend2FACodeResult = Result<true, string>;

export type ResolveAddPaymentCardResult = Result<
  {
    addPaymentCard: Extract<
      AddPaymentCardMutation["addPaymentCard"],
      { __typename: "ProfileStatus" }
    >;
    user: Extract<AddPaymentCardMutation["user"], { __typename: "User" }>;
  },
  {
    code: AddPaymentCardErrorCode;
  }
>;

export type ResolveRemoveFiatInstrumentResult = Result<
  {
    user: Extract<RemoveFiatInstrumentMutation["user"], { __typename: "User" }>;
  },
  string
>;

export type CreateThreeDomainSecureSessionResult = Result<
  ThreeDomainSecureSession | { dataShareNotSupported: true },
  string
>;

/**
 * A fallback to capture exceptions when calling our API. This is not intended for handling known errors such as validation or errors-as-data results.
 */
const reportApiError = (error: unknown, operation: string) => {
  Sentry.captureException(
    error instanceof ClientError ? error.message : error,
    { tags: { operation } },
  );
};

export const QUOTE_ERROR_LIMIT_CODES = [
  "below_min_xfer",
  "above_max_xfer",
  "above_monthly_xfer",
] as const;

export type QuoteLimitErrorCode = (typeof QUOTE_ERROR_LIMIT_CODES)[number];

export const QUOTE_ERROR_NEGATIVE_CODE = "quote_negative" as const;

export type QuoteNegativeErrorCode = typeof QUOTE_ERROR_NEGATIVE_CODE;

export const QUOTE_ERROR_PRESENTMENT_UNAVAILABLE = "presentment_unavailable";

export type QuotePresentmentUnavailableCode =
  typeof QUOTE_ERROR_PRESENTMENT_UNAVAILABLE;

export const api = (sdk: Sdk) => {
  return {
    async resolveLoginMessage({
      input,
    }: LoginMessageQueryVariables): Promise<ResolveLoginMessageResult> {
      const OPERATION_NAME = "LoginMessage";

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { loginMessage },
        } = result;

        if (!result || !loginMessage || loginMessage.__typename === "Errors") {
          Sentry.captureMessage(
            ErrorMessages.landingSheet.GENERATE_SIGNING_MESSAGE_ERROR,
            {
              level: "warning",
              tags: { operation: OPERATION_NAME },
              extra: {
                result: JSON.stringify(result),
                requestId: result.headers.get("x-meso-request") ?? "unknown",
              },
            },
          );

          return err(ErrorMessages.landingSheet.GENERATE_SIGNING_MESSAGE_ERROR);
        }

        return ok(loginMessage);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ErrorMessages.landingSheet.GENERATE_SIGNING_MESSAGE_ERROR);
    },

    async resolveSend2FACode({
      input,
    }: Send2FaCodeMutationVariables): Promise<ResolveSend2FACodeResult> {
      const OPERATION_NAME = "Send2FACode";
      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { send2FACode },
        } = result;

        if (
          !result ||
          send2FACode?.__typename === "Errors" ||
          !send2FACode?.success
        ) {
          return err(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR);
        } else {
          return ok(true);
        }
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR);
    },

    async resolveUser(): Promise<ResolveUserResult> {
      const OPERATION_NAME = "LookupUser";
      const ERROR_MESSAGE = ErrorMessages.common.LOOKUP_USER_API_ERROR;
      try {
        const result = await sdk[OPERATION_NAME]();
        const {
          data: { user },
        } = result;

        if (!user || user.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(user);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }
      return err(ERROR_MESSAGE);
    },

    async resolveBeginPasskeyRegistration(): Promise<
      Result<PasskeyCeremony, string>
    > {
      const OPERATION_NAME = "BeginPasskeyRegistration";
      const ERROR_MESSAGE =
        ErrorMessages.passkey.UNABLE_TO_BEGIN_PASSKEY_REGISTRATION;

      try {
        const result = await sdk.BeginPasskeyRegistration();
        const {
          data: { beginPasskeyRegistration },
        } = result;

        if (
          !beginPasskeyRegistration ||
          beginPasskeyRegistration.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(beginPasskeyRegistration);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveCompletePasskeyRegistration({
      input,
    }: MutationCompletePasskeyRegistrationArgs): Promise<Result<true, string>> {
      const OPERATION_NAME = "CompletePasskeyRegistration";
      const ERROR_MESSAGE = ErrorMessages.passkey.PASSKEY_REGISTRATION_FAILED;

      try {
        const result = await sdk.CompletePasskeyRegistration({ input });
        const {
          data: { completePasskeyRegistration },
        } = result;

        if (
          !completePasskeyRegistration ||
          completePasskeyRegistration.__typename === "Errors" ||
          !completePasskeyRegistration.success
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(true);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveBeginPasskeyLogin(): Promise<Result<PasskeyCeremony, string>> {
      const OPERATION_NAME = "BeginPasskeyLogin";
      const ERROR_MESSAGE = ErrorMessages.passkey.UNABLE_TO_BEGIN_PASSKEY_LOGIN;

      try {
        const result = await sdk.BeginPasskeyLogin();
        const {
          data: { beginPasskeyLogin },
        } = result;

        if (!beginPasskeyLogin || beginPasskeyLogin.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(beginPasskeyLogin);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveCompletePasskeyLogin({
      input,
    }: MutationCompletePasskeyLoginArgs): Promise<
      Result<LoginFragment, string>
    > {
      const OPERATION_NAME = "CompletePasskeyLogin";
      const ERROR_MESSAGE = ErrorMessages.passkey.PASSKEY_LOGIN_FAILED;

      try {
        const result = await sdk.CompletePasskeyLogin({ input });
        const {
          data: { completePasskeyLogin },
        } = result;

        if (
          !completePasskeyLogin ||
          completePasskeyLogin.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(completePasskeyLogin);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveLoginWithWallet({
      input,
    }: MutationPerformWalletLoginArgs): Promise<
      Result<LoginWithWalletSuccess, string>
    > {
      const OPERATION_NAME = "LoginWithWallet";
      const ERROR_MESSAGE = ErrorMessages.landingSheet.LOGIN_FAILED;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { loginWithWallet },
        } = result;

        if (!loginWithWallet || loginWithWallet.__typename === "Errors") {
          const errorCode = loginWithWallet?.errors[0].code;

          if (errorCode === "login_failed") {
            return ok({ loginWithEmailAndPassword: true });
          } else if (errorCode === "invalid_signature") {
            return err(ErrorMessages.landingSheet.WALLET_SIGNING_GENERIC_ERROR);
          }

          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(loginWithWallet);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveLoginWithEmailAndPassword({
      input,
    }: LoginWithEmailAndPasswordMutationVariables): Promise<
      Result<LoginFragment, string>
    > {
      const OPERATION_NAME = "LoginWithEmailAndPassword";
      const ERROR_MESSAGE =
        ErrorMessages.landingSheet.EMAIL_PASSWORD_LOGIN_FAILED;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { loginWithEmailAndPassword },
        } = result;

        if (
          !loginWithEmailAndPassword ||
          loginWithEmailAndPassword.__typename === "Errors"
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok(loginWithEmailAndPassword);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveAddWallet({
      input,
    }: AddWalletMutationVariables): Promise<
      Result<
        { user: Extract<AddWalletMutation["user"], { __typename: "User" }> },
        string
      >
    > {
      const OPERATION_NAME = "AddWallet";
      const ERROR_MESSAGE = ErrorMessages.addWallet.UNABLE_TO_ADD_WALLET;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { addWallet, user },
        } = result;

        if (
          !addWallet ||
          addWallet.__typename === "Errors" ||
          !user ||
          user.__typename === "Errors"
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok({ user });
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolvePartnerDetails(): Promise<
      Result<PartnerQuery["partner"], string>
    > {
      const OPERATION_NAME = "Partner";
      const ERROR_MESSAGE =
        ErrorMessages.partner.UNABLE_TO_RESOLVE_PARTNER_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]();
        const {
          data: { partner },
        } = result;

        if (!partner) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(partner);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ErrorMessages.partner.TERMINAL_ERROR);
    },

    async resolveActivateUser({
      input,
    }: PerformUserActivationMutationVariables): Promise<ResolveActivateUserResult> {
      const OPERATION_NAME = "PerformUserActivation";
      const ERROR_MESSAGE = "Something went wrong. Please try again.";

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { activateUser },
        } = result;

        if (!activateUser || activateUser.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(activateUser);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveVerify2FA({
      input,
    }: Verify2FaMutationVariables): Promise<ResolveVerify2FAResult> {
      const OPERATION_NAME = "Verify2FA";
      const ERROR_MESSAGE =
        ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { verify2FA },
        } = result;

        if (
          !verify2FA ||
          verify2FA.__typename === "Errors" ||
          !verify2FA.success
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok(true);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveRiskSession(): Promise<ResolveRiskSessionResult> {
      const OPERATION_NAME = "RiskSession";
      const ERROR_MESSAGE = "Unable to initialize the Meso transfer flow.";

      try {
        const result = await sdk[OPERATION_NAME]();
        const {
          data: { riskSession },
        } = result;

        if (!result || !riskSession || riskSession.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(riskSession);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveExecuteTransfer({
      input,
    }: ExecuteTransferMutationVariables): Promise<
      Result<
        Extract<
          ExecuteTransferMutation["executeTransfer"],
          { __typename: "Transfer" }
        >,
        string
      >
    > {
      const OPERATION_NAME = "ExecuteTransfer";
      const ERROR_MESSAGE =
        ErrorMessages.transferSheet.EXECUTE_TRANSFER_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { executeTransfer },
        } = result;

        if (!result || !executeTransfer) {
          return err(ERROR_MESSAGE);
        }

        if (executeTransfer.__typename === "Errors") {
          const transferErrors = executeTransfer?.errors || [];

          if (transferErrors.find((e) => e.code === "quote_already_executed")) {
            return err(
              ErrorMessages.transferSheet.QUOTE_ALREADY_EXECUTED_API_ERROR,
            );
          } else if (
            transferErrors.find((e) => e.code === "quote_jwt_expired")
          ) {
            return err(ErrorMessages.transferSheet.QUOTE_EXPIRED_API_ERROR);
          } else {
            return err(ERROR_MESSAGE);
          }
        }

        return ok(executeTransfer);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveNewSession({
      input,
    }: NewSessionMutationVariables): Promise<
      Result<
        Extract<NewSessionMutation["newSession"], { __typename: "Session" }>,
        string
      >
    > {
      const OPERATION_NAME = "NewSession";
      const ERROR_MESSAGE = ErrorMessages.newSession.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { newSession },
        } = result;

        if (!result || !newSession || newSession.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(newSession);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveUserActivationStatus({
      id,
    }: ResolveUserActivationStatusQueryVariables): Promise<ResolveUserActivationStatusResult> {
      const OPERATION_NAME = "ResolveUserActivationStatus";
      const ERROR_MESSAGE = ErrorMessages.userActivation.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ id });
        const {
          data: { userActivation },
        } = result;

        if (
          !result ||
          !userActivation ||
          userActivation?.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(userActivation);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveQuote({
      input,
    }: QuoteQueryVariables): Promise<ResolveQuoteResult> {
      const OPERATION_NAME = "Quote";
      const ERROR_MESSAGE = ErrorMessages.quote.GENERIC_API_ERROR;
      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { quote },
        } = result;

        if (!result || !quote) {
          return err({
            message: ERROR_MESSAGE,
            isLimitError: false,
          });
        }

        if (quote?.__typename === "Errors") {
          const negativeErr = quote.errors.find(
            (e) => e.code == QUOTE_ERROR_NEGATIVE_CODE,
          );

          const limitErr = quote.errors.find((e) =>
            QUOTE_ERROR_LIMIT_CODES.includes(e.code as QuoteLimitErrorCode),
          );

          const presentmentErr = quote.errors.find(
            (e) => e.code == QUOTE_ERROR_PRESENTMENT_UNAVAILABLE,
          );

          if (limitErr) {
            return err({
              code: limitErr?.code as QuoteLimitErrorCode | undefined,
              message: limitErr?.enMessage || ERROR_MESSAGE,
              isLimitError: limitErr !== undefined,
            });
          } else if (negativeErr) {
            return err({
              code: negativeErr?.code as QuoteNegativeErrorCode,
              message: ErrorMessages.quote.NETWORK_FEE_EXCEEDS_TOTAL,
              isLimitError: false,
            });
          } else if (presentmentErr) {
            return err({
              code: presentmentErr?.code as QuotePresentmentUnavailableCode,
              message: presentmentErr?.enMessage || ERROR_MESSAGE,
              isLimitError: false,
            });
          } else {
            return err({
              code: undefined,
              message: ERROR_MESSAGE,
              isLimitError: false,
            });
          }
        }

        return ok(quote);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err({
        message: ERROR_MESSAGE,
        isLimitError: false,
      });
    },

    async resolveAnonQuote({
      input,
    }: AnonQuoteQueryVariables): Promise<
      Result<
        Extract<AnonQuoteQuery["anonQuote"], { __typename: "AnonQuote" }>,
        QuoteError
      >
    > {
      const OPERATION_NAME = "AnonQuote";
      const ERROR_MESSAGE = ErrorMessages.quote.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { anonQuote },
        } = result;

        if (!result || !anonQuote) {
          return err({
            message: ERROR_MESSAGE,
            isLimitError: false,
          });
        }

        if (anonQuote?.__typename === "Errors") {
          const negativeErr = anonQuote.errors.find(
            (e) => e.code == QUOTE_ERROR_NEGATIVE_CODE,
          );

          const limitErr = anonQuote.errors.find((e) =>
            QUOTE_ERROR_LIMIT_CODES.includes(e.code as QuoteLimitErrorCode),
          );

          if (limitErr) {
            return err({
              code: limitErr?.code as QuoteLimitErrorCode | undefined,
              message: limitErr?.enMessage || ERROR_MESSAGE,
              isLimitError: limitErr !== undefined,
            });
          } else if (negativeErr) {
            return err({
              code: negativeErr?.code as QuoteNegativeErrorCode,
              message: ErrorMessages.quote.NETWORK_FEE_EXCEEDS_TOTAL,
              isLimitError: false,
            });
          } else {
            return err({
              code: undefined,
              message: ERROR_MESSAGE,
              isLimitError: false,
            });
          }
        }

        return ok(anonQuote);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err({
        message: ERROR_MESSAGE,
        isLimitError: false,
      });
    },

    async resolveCreateCashOutQuote({
      input,
    }: CreateCashOutQuoteMutationVariables): Promise<
      Result<AuthorizedCashOutQuote, QuoteError>
    > {
      const OPERATION_NAME = "CreateCashOutQuote";
      const ERROR_MESSAGE = ErrorMessages.cashOutQuote.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { createCashOutQuote },
        } = result;

        if (!createCashOutQuote || createCashOutQuote.__typename === "Errors") {
          const limitErr = createCashOutQuote?.errors.find((e) =>
            QUOTE_ERROR_LIMIT_CODES.includes(e.code as QuoteLimitErrorCode),
          );
          return err({
            isLimitError: !!limitErr,
            message: limitErr?.enMessage || ERROR_MESSAGE,
          });
        }

        return ok(createCashOutQuote);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err({ isLimitError: false, message: ERROR_MESSAGE });
    },

    async resolveCreateAnonCashOutQuote({
      input,
    }: CreateAnonCashOutQuoteMutationVariables): Promise<
      Result<AnonCashOutQuote, QuoteError>
    > {
      const OPERATION_NAME = "CreateAnonCashOutQuote";
      const ERROR_MESSAGE = ErrorMessages.cashOutQuote.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { createAnonCashOutQuote },
        } = result;

        if (
          !createAnonCashOutQuote ||
          createAnonCashOutQuote.__typename === "Errors"
        ) {
          const limitErr = createAnonCashOutQuote?.errors.find((e) =>
            QUOTE_ERROR_LIMIT_CODES.includes(e.code as QuoteLimitErrorCode),
          );
          return err({
            isLimitError: !!limitErr,
            message: limitErr?.enMessage || ERROR_MESSAGE,
          });
        }

        return ok(createAnonCashOutQuote);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err({ isLimitError: false, message: ERROR_MESSAGE });
    },

    async resolveCheckTransfer({
      id,
    }: CheckTransferQueryVariables): Promise<ResolveCheckTransferResult> {
      const OPERATION_NAME = "CheckTransfer";
      const API_ERROR_MESSAGE =
        ErrorMessages.transferStatus.TRANSFER_STATUS_API_ERROR;
      const CLIENT_ERROR_MESSAGE =
        ErrorMessages.transferStatus.TRANSFER_STATUS_CLIENT_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ id });
        const {
          data: { transfer },
          errors,
        } = result;

        if (errors) {
          if (isAuthFactorError(errors)) {
            // Don't report auth factor errors to sentry
            return err(CLIENT_ERROR_MESSAGE);
          }
        }

        if (
          errors ||
          !result ||
          !transfer ||
          transfer?.__typename === "Errors"
        ) {
          Sentry.captureMessage(API_ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(CLIENT_ERROR_MESSAGE);
        }

        return ok(transfer);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);

        return err(CLIENT_ERROR_MESSAGE);
      }
    },

    async resolveCreateThreeDomainSecureSession({
      input,
    }: CreateThreeDomainSecureSessionMutationVariables): Promise<CreateThreeDomainSecureSessionResult> {
      const OPERATION_NAME = "CreateThreeDomainSecureSession";
      const ERROR_MESSAGE =
        ErrorMessages.threeDomainSecure.CREATE_3DS_SECURE_SESSION_CLIENT_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { createThreeDomainSecureSession },
          errors,
        } = result;

        if (errors) {
          if (isAuthFactorError(errors)) {
            // Don't report auth factor errors to sentry
            return err(ERROR_MESSAGE);
          }
        }

        if (
          errors ||
          !result ||
          !createThreeDomainSecureSession ||
          createThreeDomainSecureSession?.__typename === "Errors"
        ) {
          if (createThreeDomainSecureSession?.__typename === "Errors") {
            const apiErrors = createThreeDomainSecureSession.errors;

            if (apiErrors.find((e) => e.code === "data_share_not_supported")) {
              return ok({ dataShareNotSupported: true });
            }
          }

          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              input: JSON.stringify(input),
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(createThreeDomainSecureSession);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);

        return err(ERROR_MESSAGE);
      }
    },

    async resolveSetThreeDomainSecureSessionMethodCompletion({
      input,
    }: SetThreeDomainSecureSessionMethodCompletionMutationVariables): Promise<
      Result<ThreeDomainSecureSession, string>
    > {
      const OPERATION_NAME = "SetThreeDomainSecureSessionMethodCompletion";
      const REPORTABLE_ERROR_MESSAGE =
        ErrorMessages.threeDomainSecure.SET_3DS_SESSION_METHOD_API_ERROR;
      const CLIENT_ERROR_MESSAGE =
        ErrorMessages.threeDomainSecure.SET_3DS_SESSION_METHOD_CLIENT_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { setThreeDomainSecureSessionMethodCompletion },
        } = result;

        if (
          !result ||
          !setThreeDomainSecureSessionMethodCompletion ||
          setThreeDomainSecureSessionMethodCompletion?.__typename === "Errors"
        ) {
          Sentry.captureMessage(REPORTABLE_ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
              userErrorSeen:
                ErrorMessages.threeDomainSecure
                  .SET_3DS_SESSION_METHOD_CLIENT_ERROR,
            },
          });

          return err(CLIENT_ERROR_MESSAGE);
        }

        return ok(setThreeDomainSecureSessionMethodCompletion);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);

        return err(CLIENT_ERROR_MESSAGE);
      }
    },

    async resolveSubmitUserFeedback({
      input,
    }: SubmitUserFeedbackMutationVariables): Promise<Result<string, string>> {
      const OPERATION_NAME = "SubmitUserFeedback";
      const ERROR_MESSAGE = "Failed to submit feedback";

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { createUserFeedback },
        } = result;

        if (
          !result ||
          !createUserFeedback ||
          createUserFeedback?.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok("submitted");
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);

        return err(ERROR_MESSAGE);
      }
    },

    async resolveRequestApplePayPaymentSession({
      input,
    }: RequestApplePayPaymentSessionMutationVariables): Promise<
      Result<ApplePayPaymentSession, string>
    > {
      const OPERATION_NAME = "RequestApplePayPaymentSession";
      const ERROR_MESSAGE = ErrorMessages.applePay.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { requestApplePayPaymentSession },
        } = result;

        if (
          !result ||
          !requestApplePayPaymentSession ||
          requestApplePayPaymentSession?.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        const { merchantSession } = requestApplePayPaymentSession;
        try {
          const parsedMerchantSession = JSON.parse(merchantSession);
          return ok(parsedMerchantSession);
        } catch (e) {
          Sentry.captureException(e, { extra: { merchantSession } });
          return err(ERROR_MESSAGE);
        }
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
        return err(ERROR_MESSAGE);
      }
    },

    async resolveAddPaymentCard({
      input,
    }: AddPaymentCardMutationVariables): Promise<ResolveAddPaymentCardResult> {
      const OPERATION_NAME = "AddPaymentCard";
      const defaultError: { code: AddPaymentCardErrorCode } = {
        code: "unknown",
      };

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { addPaymentCard, user },
        } = result;

        if (!addPaymentCard || !user || user.__typename === "Errors") {
          return err(defaultError);
        }

        if (addPaymentCard && addPaymentCard.__typename === "Errors") {
          const errorCode = addPaymentCard.errors[0].code;

          if (isAddPaymentCardErrorCode(errorCode)) {
            return err({ code: errorCode });
          }

          return err(defaultError);
        }

        if (user.__typename === "User") {
          return ok({
            addPaymentCard,
            user,
          });
        }

        return err(defaultError);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(defaultError);
    },

    async resolveRemoveFiatInstrument({
      input,
    }: RemoveFiatInstrumentMutationVariables): Promise<ResolveRemoveFiatInstrumentResult> {
      const OPERATION_NAME = "RemoveFiatInstrument";
      const ERROR_MESSAGE =
        ErrorMessages.paymentMethod.REMOVE_FIAT_INSTRUMENT_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { removeFiatInstrument, user },
        } = result;

        if (
          !removeFiatInstrument ||
          removeFiatInstrument.__typename === "Errors" ||
          !removeFiatInstrument.success ||
          !user ||
          user.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        if (user.__typename === "User") {
          return ok({ user });
        }

        return err(ERROR_MESSAGE);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveUpdateDataConsent({
      input,
    }: UpdateDataConsentMutationVariables): Promise<Result<true, string>> {
      const OPERATION_NAME = "UpdateDataConsent";
      const ERROR_MESSAGE = ErrorMessages.dataConsent.GENERIC_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { updateDataConsent },
        } = result;

        if (
          !updateDataConsent ||
          updateDataConsent.__typename === "Errors" ||
          !updateDataConsent.success
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(true);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },
  };
};
