import { Text, Button, CustomToastError } from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPassport, faCar, faIdCard } from "@fortawesome/pro-solid-svg-icons";
import {
  faIdCard as faIdCardRegular,
  faCake,
  faSignature,
  faHouseCircleCheck,
} from "@fortawesome/pro-regular-svg-icons";
import {
  FormEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { useRouter } from "../hooks/useRouter";
import { CountryCodeAlpha2, Routes, UrlString } from "../types";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import { toast } from "sonner";
import {
  DocumentVerificationDialog,
  DocumentVerificationProps,
} from "./DocumentVerificationDialog";

const TOAST_ID = "BasicInfoOverview";
const ICON_SIZE = "w-6 w-6 text-primary dark:text-primary-light ";

const renderToast = () => {
  // We explicitly do not use a toast ID here so that we can dismiss and re-render the toast quickly
  toast.error(
    <CustomToastError
      title="Something went wrong"
      body={
        <div>
          If the issue persists, please{" "}
          <a
            href="https://support.meso.network"
            target="_blank"
            rel="noreferrer"
            className="underline opacity-80 transition-opacity hover:opacity-100"
          >
            contact support.
          </a>
        </div>
      }
    />,
  );
};

const NonUSPrimer = () => {
  const ICON_CLASS_NAME =
    "px-4 py-3 text-xs font-medium flex items-center gap-2";

  return (
    <div className="divide-y divide-neutral-800/5 dark:divide-neutral-600">
      <div className={ICON_CLASS_NAME}>
        <FontAwesomeIcon icon={faPassport} className="text-[#3373FF]" />
        Passport
      </div>
      <div className={ICON_CLASS_NAME}>
        <FontAwesomeIcon icon={faCar} className="text-[#ED64A6]" />
        Driving License
      </div>
      <div className={ICON_CLASS_NAME}>
        <FontAwesomeIcon icon={faIdCard} className="text-[#E1AF4F]" />
        Similar government issued ID
      </div>
    </div>
  );
};

const USPrimer = () => (
  <div className="flex flex-col gap-2 p-4">
    <div className="flex items-center gap-3">
      <FontAwesomeIcon icon={faIdCardRegular} className={ICON_SIZE} />
      <div className="flex flex-col">
        <Text className="font-bold">Social Security</Text>
        <Text className="opacity-70">Just the last 4-digits</Text>
      </div>
    </div>
    <div className="flex items-center gap-3">
      <FontAwesomeIcon icon={faCake} className={ICON_SIZE} />
      <div className="flex flex-col">
        <Text className="font-bold">Birthday</Text>
        <Text className="opacity-70">Date of birth</Text>
      </div>
    </div>
    <div className="flex items-center gap-3">
      <FontAwesomeIcon icon={faSignature} className={ICON_SIZE} />
      <div className="flex flex-col">
        <Text className="font-bold">Name</Text>
        <Text className="opacity-70">Your legal first and last name</Text>
      </div>
    </div>
    <div className="flex items-center gap-3">
      <FontAwesomeIcon icon={faHouseCircleCheck} className={ICON_SIZE} />
      <div className="flex flex-col">
        <Text className="font-bold">Residential Address</Text>
        <Text className="opacity-70">Your legal address</Text>
      </div>
    </div>
  </div>
);

export const BasicInfoOverview = () => {
  const {
    api: { resolveUser, resolveCreateIdentityDocumentUrl },
  } = useApi();
  const { navigate } = useRouter();
  const { user, updateUser } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const [identityDocumentUrl, setIdentityDocumentUrl] =
    useState<UrlString | null>(null);
  const [showIframe, setShowIframe] = useState(false);
  const isUSUser = useMemo(
    () => user.residenceCountry === CountryCodeAlpha2.US,
    [user.residenceCountry],
  );
  const idvComplete = useRef(false);

  const resolveUserAndNavigate = useCallback(async () => {
    // Re-fetch user
    const userResult = await resolveUser();

    if (userResult.isErr()) {
      renderToast();
      setIsLoading(false);
      return;
    }

    // We don't want to do a full user update here because the `User` we get from the server will not have `residentialAddress` at this point in onboarding
    // and we don't want to overwrite that field
    updateUser({
      firstName: userResult.value.firstName,
      lastName: userResult.value.lastName,
      dateOfBirth: userResult.value.dateOfBirth,
    });

    // UI buffer to allow the loading transition to occur
    setTimeout(() => navigate(Routes.PersonalInfo), 500);
  }, [navigate, resolveUser, updateUser]);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      toast.dismiss();

      if (isUSUser) {
        navigate(Routes.BasicInfoEntry);
      } else {
        if (idvComplete.current) {
          await resolveUserAndNavigate();
        } else {
          // Initiate IDV flow
          setIsLoading(true);

          const identityDocumentUrlResult =
            await resolveCreateIdentityDocumentUrl();

          if (identityDocumentUrlResult.isOk()) {
            setIdentityDocumentUrl(
              identityDocumentUrlResult.value.url as UrlString,
            );
            setShowIframe(true);
          } else {
            renderToast();
          }

          // UI buffer to allow the loading transition to occur
          setTimeout(() => setIsLoading(false), 500);
        }
      }
    },
    [
      isUSUser,
      navigate,
      resolveCreateIdentityDocumentUrl,
      resolveUserAndNavigate,
    ],
  );

  const handleDocumentVerificationComplete = useCallback<
    DocumentVerificationProps["onComplete"]
  >(
    async (status) => {
      setShowIframe(false);
      toast.dismiss(TOAST_ID);

      if (status === "error") {
        renderToast();
      } else if (status === "success") {
        setIsLoading(true);
        idvComplete.current = true;
        await resolveUserAndNavigate();
      }
    },
    [resolveUserAndNavigate],
  );

  return (
    <AnimationContainer>
      <form
        id="OnboardingBasicInfoOverview"
        name="OnboardingBasicInfoOverview"
        onSubmit={handleSubmit}
        className="onboarding-inner-content"
      >
        <Heading
          title="Identity Verification"
          subtitle={
            <div className="mb-4">
              We need a few more details to protect your account and verify your
              identity.
            </div>
          }
        />

        <div className="rounded-xl border border-solid border-neutral-800/5 shadow-md dark:border-neutral-600">
          {isUSUser ? <USPrimer /> : <NonUSPrimer />}
        </div>

        <div className="onboarding-footer">
          <Button
            key="OnboardingBasicInfoOverview:button"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {isUSUser ? "Continue" : "Upload Documents"}
          </Button>
        </div>
      </form>

      <DocumentVerificationDialog
        identityDocumentUrl={identityDocumentUrl!}
        onComplete={handleDocumentVerificationComplete}
        showIframe={showIframe}
      />
    </AnimationContainer>
  );
};
