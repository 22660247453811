import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { getCountryCallingCode } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en";
import { twMerge } from "tailwind-merge";
import { useCallback, useContext, useMemo } from "react";
import { Country } from "../utils/countries";
import { CountryCodeAlpha2 } from "../types";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MesoKitContext } from "../MesoKitContext";
import { ErrorMessages } from "../utils/errorMessages";

export type PhoneCountrySelectProps = {
  value: CountryCodeAlpha2;
  onChange: (country: CountryCodeAlpha2) => void;
  /** Whether to allow the selecting the country.
   *
   * If `static` is true, the `value` prop will be used and the `onChange` event will not fire. */
  static?: boolean;
  countries: Country[];
};

// via: https://dev.to/jorik/country-code-to-flag-emoji-a21
function getFlagEmoji(countryCode: CountryCodeAlpha2) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => {
      return 127397 + char.charCodeAt(0);
    });
  return String.fromCodePoint(...codePoints);
}

export const PhoneCountrySelect = ({
  value,
  onChange,
  static: _static = false,
  countries,
}: PhoneCountrySelectProps) => {
  const { sentry } = useContext(MesoKitContext);
  const countryOptions = useMemo(() => {
    return countries.map((country) => ({
      id: country.countryCodeAlpha2,
      icon: getFlagEmoji(country.countryCodeAlpha2),
      callingCode: getCountryCallingCode(country.countryCodeAlpha2),
      name: en[country.countryCodeAlpha2],
      countryCodeAlpha2: country.countryCodeAlpha2,
    }));
  }, [countries]);

  const isStatic = useMemo(
    () => _static || countries.length === 1,
    [_static, countries.length],
  );

  const countryDetails = useMemo(() => {
    const currentCountryDetails = countryOptions.find(
      ({ countryCodeAlpha2 }) => {
        return countryCodeAlpha2 === value;
      },
    );

    if (!currentCountryDetails) {
      sentry?.captureMessage(
        ErrorMessages.debug.UNABLE_TO_GET_COUNTRY_DETAILS,
        {
          level: "warning",
          extra: {
            value,
            countryOptions: JSON.stringify(countryOptions),
          },
        },
      );

      // Default to US if unable to match country
      return countryOptions.find(({ countryCodeAlpha2 }) => {
        return countryCodeAlpha2 === CountryCodeAlpha2.US;
      })!;
    }

    return currentCountryDetails;
  }, [countryOptions, sentry, value]);

  const handleChange = useCallback<PhoneCountrySelectProps["onChange"]>(
    (country) => {
      if (!isStatic) {
        onChange(country);
      }
    },
    [onChange, isStatic],
  );

  return (
    <Listbox value={value} onChange={handleChange} disabled={isStatic}>
      <ListboxButton
        className={twMerge(
          "mr-1 flex w-16 items-center justify-center gap-2 rounded-xl border text-xs font-bold transition-opacity dark:border-0 dark:bg-neutral-700",
          !isStatic && "hover:opacity-80",
        )}
        data-testid="PhoneCountrySelect:button"
      >
        +{countryDetails.callingCode}
        {!isStatic && (
          <FontAwesomeIcon
            icon={faChevronDown}
            className="dark:text-white"
            data-testid="PhoneCountrySelect:icon"
          />
        )}
      </ListboxButton>
      <ListboxOptions
        anchor="bottom"
        transition
        className={twMerge(
          "flex flex-col gap-1 rounded-lg bg-neutral-100 dark:bg-neutral-700",
          // headlessui classes for transitions
          "origin-top transition duration-200 ease-out data-closed:scale-95 data-closed:opacity-0",
        )}
        data-testid="PhoneCountrySelect:options"
      >
        {countryOptions.map(({ icon, name, countryCodeAlpha2 }) => (
          <ListboxOption
            key={countryCodeAlpha2}
            value={countryCodeAlpha2}
            className="flex cursor-pointer items-center gap-2 px-4 py-1 text-sm font-medium opacity-80 data-focus:bg-neutral-800/50 dark:text-white"
            data-testid="PhoneCountrySelect:option"
            data-testvalue={countryCodeAlpha2}
          >
            <div>{icon}</div>
            <div>{name}</div>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};
