/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, KeyboardEvent, useEffect, useRef } from "react";
import { Input } from "./Input";
import { Text } from "./Text";
import { twMerge } from "tailwind-merge";
import { CountryCodeAlpha2 } from "../types";
import { countries, Country, NullableCountry } from "../utils/countries";
import { useFuzzySearchList } from "@nozbe/microfuzz/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faMapPin } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

export type CountryListProps = {
  /** A callback that will be dispatched when a country is selected. If no country is selected, `null` will be provided and you can assume the input is invalid. */
  onSelectCountry: (countryCode: CountryCodeAlpha2 | null) => void;
  /** Whether to disable the combobox input. Defaults to `false`. */
  disabled?: boolean;
  /** Placeholder text for the combobox input. Defaults to "Search countries". */
  placeholder?: string;
  /** The initial value for the combobox. */
  initialCountry?: CountryCodeAlpha2;
};

export const CountryList = ({
  onSelectCountry,
  disabled = false,
  placeholder = "Search countries",
}: CountryListProps) => {
  const [selectedCountry, setSelectedCountry] = useState<NullableCountry>(null);
  const [query, setQuery] = useState("");
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const filteredCountries = useFuzzySearchList({
    list: countries,
    queryText: query,
    getText: useCallback(
      (country: Country) => [country.display, country.icon],
      [],
    ),
    mapResultItem: useCallback(({ item }: { item: Country }) => item, []),
  });

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleConfirmationBubble = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setShowConfirmation(true);

    timeoutRef.current = setTimeout(() => {
      setShowConfirmation(false);
    }, 3000);
  }, []);

  useEffect(() => {
    setFocusedIndex(-1);
  }, [query]);

  useEffect(() => {
    handleConfirmationBubble();
    onSelectCountry(selectedCountry?.countryCodeAlpha2 ?? null);
  }, [selectedCountry]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (disabled) return;

      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setFocusedIndex((prev) => {
            if (prev >= filteredCountries.length - 1) return 0;
            return prev + 1;
          });
          break;
        case "ArrowUp":
          e.preventDefault();
          setFocusedIndex((prev) => {
            if (prev <= 0) return filteredCountries.length - 1;
            return prev - 1;
          });
          break;
        case "Enter":
          e.preventDefault();
          if (focusedIndex >= 0 && focusedIndex < filteredCountries.length) {
            setSelectedCountry(filteredCountries[focusedIndex]);
          }
          break;
      }
    },
    [disabled, filteredCountries, focusedIndex],
  );

  return (
    <div className="relative flex h-full w-full flex-1 flex-col">
      <Input
        value={query}
        isValid
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        disabled={disabled}
        data-testid="CountryList:input"
        id="CountryList_search"
      />
      <div className="mt-2 flex h-72 w-full flex-auto flex-col overflow-y-auto">
        {filteredCountries.length > 0 ? (
          filteredCountries.map((country, index) => (
            <div
              key={country.countryCodeAlpha2}
              onClick={() => {
                setSelectedCountry(country);
              }}
              className={twMerge(
                "dark:hover:bg-primary-light/50 flex w-full cursor-pointer items-center rounded-xl px-4 py-3 text-sm transition hover:bg-neutral-200 dark:text-white",
                (selectedCountry?.countryCodeAlpha2 ===
                  country.countryCodeAlpha2 ||
                  focusedIndex === index) &&
                  "dark:bg-primary-light bg-primary text-white",
              )}
            >
              {country.icon} {country.display}
              <AnimatePresence>
                {selectedCountry?.countryCodeAlpha2 ===
                  country.countryCodeAlpha2 && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    className="ml-auto"
                  >
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-white"
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))
        ) : (
          <div className="flex h-full w-full cursor-pointer items-center rounded-xl px-4 py-2 text-xs dark:text-white">
            <div className="flex w-full flex-col justify-center text-center">
              <Text className="font-bold">No results found</Text>
              <Text>We may not support this country yet.</Text>
            </div>
          </div>
        )}
      </div>
      <AnimatePresence>
        {selectedCountry && showConfirmation && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8, y: 24 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.8, y: 24 }}
            className="absolute bottom-0 inline-flex items-center gap-2 self-center rounded-full bg-neutral-200 px-4 py-2 dark:bg-neutral-700"
          >
            <FontAwesomeIcon
              icon={faMapPin}
              className="text-primary dark:text-primary-light"
            />
            <Text>You live in {selectedCountry.display}</Text>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
