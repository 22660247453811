import { useCallback, useContext, useState } from "react";
import {
  AddPaymentCardForm,
  AddPaymentCardFormProps,
  addPaymentCardToastError,
  nonUSCountries,
  spring,
} from "@tigris/mesokit";
import {
  AllowedEEAIssuingBankCountryCodes,
  AppContextValue,
  CountryCodeAlpha2,
  CountryCodeAlpha3,
} from "@src/types";
import { AppContext } from "@src/contexts/AppContextProvider";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "sonner";
import { useOnboarding } from "@src/hooks/useOnboarding";

const TOAST_ID = "AddDebitCard";

export const AddDebitCard = () => {
  const {
    setFiatInstrument,
    setIsAddingCard,
    api: { resolveAddPaymentCard },
    updateUser,
    user,
  } = useContext<AppContextValue>(AppContext);
  const { supportedPaymentMethods } = useOnboarding();
  const [formEnabled, setFormEnabled] = useState(true);
  const [initialValues] = useState<
    AddPaymentCardFormProps["initialValues"] | undefined
  >(() => {
    if (user.firstName && user.lastName && user.residentialAddress) {
      // This component accepts our API model's notion of a `residentialAddress`. Whether the address is US or Int'l shouldn't matter here.
      return {
        initialInputMode: "manual",
        cardholderName: `${user.firstName} ${user.lastName}`,
        billingAddress: {
          addressLine1: user.residentialAddress.address1,
          addressLine2: user.residentialAddress.address2 ?? "",
          city: user.residentialAddress.city,
          state: user.residentialAddress.region,
          zip: user.residentialAddress.postalCode,
          country: user.residentialAddress.countryCode as CountryCodeAlpha2,
        },
      };
    }

    return undefined;
  });
  const [initialCountryCodeAlpha3] = useState<CountryCodeAlpha3>(() => {
    if (
      user.residentialAddress &&
      user.residentialAddress.countryCode !== CountryCodeAlpha2.US
    ) {
      return nonUSCountries.find(
        ({ countryCodeAlpha2 }) =>
          user.residentialAddress?.countryCode === countryCodeAlpha2,
      )!.countryCodeAlpha3;
    }

    return CountryCodeAlpha3.USA;
  });

  const handleTokenizationSuccess = useCallback<
    AddPaymentCardFormProps["onTokenizationSuccess"]
  >(
    async (token) => {
      setFormEnabled(false);

      const result = await resolveAddPaymentCard({
        input: { token, skipReplace: true },
      });

      if (
        result.isOk() &&
        result.value.user.fiatInstruments?.__typename === "FiatInstruments" &&
        result.value.user.fiatInstruments
      ) {
        const fiatInstruments = result.value.user.fiatInstruments;
        updateUser({ fiatInstruments }, supportedPaymentMethods);

        const nextFiatInstrument = fiatInstruments.collection.reduce(
          (accumulator, fi) =>
            accumulator!.createdAt > fi.createdAt ? accumulator : fi,
          fiatInstruments.collection.at(0),
        );

        nextFiatInstrument && setFiatInstrument(nextFiatInstrument);
        setIsAddingCard(false);
      } else if (result.isErr()) {
        toast?.error(addPaymentCardToastError(result.error.code), {
          id: TOAST_ID,
        });
        setFormEnabled(true);
      }
    },
    [
      resolveAddPaymentCard,
      setFiatInstrument,
      setIsAddingCard,
      supportedPaymentMethods,
      updateUser,
    ],
  );

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={"AddDebitCard:AddPaymentCardForm"}
        initial={{ x: -48, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 48, opacity: 0 }}
        transition={spring}
        className="h-full p-6 text-neutral-800 dark:text-white"
      >
        <button
          type="button"
          data-testid="CloseAddDebitCardFormButton"
          onClick={() => setIsAddingCard(false)}
          className="absolute top-5.5 right-6 z-10 h-8 w-10 cursor-pointer rounded-full border border-neutral-800/10 text-sm opacity-60 transition-opacity hover:bg-neutral-800/5 hover:opacity-100 active:scale-95 dark:border-white/10 dark:hover:bg-white/5"
        >
          <FontAwesomeIcon icon={faXmark} size="xl" />
        </button>
        <AddPaymentCardForm
          formEnabled={formEnabled}
          onTokenizationSuccess={handleTokenizationSuccess}
          formId="AddDebitCardForm"
          initialValues={initialValues}
          international={initialCountryCodeAlpha3 !== CountryCodeAlpha3.USA}
          initialCountryCodeAlpha3={initialCountryCodeAlpha3}
          allowedIssuingBankCountries={
            initialCountryCodeAlpha3 === CountryCodeAlpha3.USA
              ? [CountryCodeAlpha2.US]
              : Object.values(AllowedEEAIssuingBankCountryCodes)
          }
        />
      </motion.div>
    </AnimatePresence>
  );
};
