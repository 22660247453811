import { useCallback, useMemo, useState } from "react";
import { useApi } from "../hooks/useApi";
import { err, ok, Result } from "neverthrow";
import { USAgreements, USAgreementsProps } from "../components/USAgreements";
import { useOnboarding } from "./useOnboarding";
import { CountryCodeAlpha2 } from "../types";

const TERMS_URL = "https://meso.network/legal/terms-of-service";
const PRIVACY_POLICY_URL = "https://meso.network/legal/";

export type UseAgreementsHook = {
  checked: boolean;
  /** The Agreements component to render. */
  Agreements: () => JSX.Element;
  /** Submit agreements to API. */
  submitAgreements: () => Promise<Result<string, string>>;
};

/** A utility hook to encapsulate the presentation and submission of user agreements. */
export const useAgreements = (): UseAgreementsHook => {
  const {
    api: {
      resolveAcceptMesoTerms,
      resolveAcceptZeroHashTerms,
      resolveAddCitizenship,
    },
  } = useApi();
  const { user } = useOnboarding();
  const [checked, setIsChecked] = useState(
    user.residenceCountry !== CountryCodeAlpha2.US &&
      user.residenceCountry !== undefined,
  );

  const handleValidationChange = useCallback<
    USAgreementsProps["onValidationChange"]
  >((isChecked) => setIsChecked(isChecked), []);

  const AgreementsComponent = useMemo(
    () => () =>
      user.residenceCountry === CountryCodeAlpha2.US ? (
        <USAgreements
          onValidationChange={handleValidationChange}
          termsUrl={TERMS_URL}
          privacyPolicyUrl={PRIVACY_POLICY_URL}
        />
      ) : (
        <div
          className="text-[0.625rem] leading-tight"
          data-testid="NonUS:Agreements"
        >
          By clicking continue you agree to the{" "}
          <a
            href={TERMS_URL}
            target="_blank"
            rel="noreferrer"
            className="font-bold no-underline hover:opacity-50"
          >
            Meso User Agreement
          </a>{" "}
          and have read and understand the{" "}
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noreferrer"
            className="font-bold no-underline hover:opacity-50"
          >
            Meso Privacy Policy
          </a>
          .
        </div>
      ),
    [handleValidationChange, user.residenceCountry],
  );

  const submitAgreements = useCallback<
    UseAgreementsHook["submitAgreements"]
  >(async () => {
    // Accept Meso's terms
    const acceptMesoTermsResult = await resolveAcceptMesoTerms({
      input: {
        acceptedPrivacyVersion: "meso:privacyPolicy:v1",
        acceptedTermsVersion: "meso:terms:v1",
      },
    });

    if (acceptMesoTermsResult.isErr()) {
      return err(acceptMesoTermsResult.error);
    }

    if (user.residenceCountry === CountryCodeAlpha2.US) {
      // Note: Once we move off of ZH, remove this call and the addCitizenship call
      // Accept ZeroHash's terms
      const acceptZeroHashTermsResult = await resolveAcceptZeroHashTerms();

      if (acceptZeroHashTermsResult.isErr()) {
        return err(acceptZeroHashTermsResult.error);
      }

      // Add user's citizenship
      const addCitizenshipResult = await resolveAddCitizenship({
        input: { countryCode: "US" },
      });

      if (addCitizenshipResult.isErr()) {
        return err(addCitizenshipResult.error);
      }
    }

    return ok("");
  }, [
    resolveAcceptMesoTerms,
    resolveAcceptZeroHashTerms,
    resolveAddCitizenship,
    user.residenceCountry,
  ]);

  return useMemo<UseAgreementsHook>(
    () => ({
      checked,
      Agreements: AgreementsComponent,
      submitAgreements,
    }),
    [AgreementsComponent, checked, submitAgreements],
  );
};
