import { Logo, Confirmation } from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLifeRing } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { UrlString } from "../types";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

import {
  DocumentVerificationResult,
  useAwaitInlineDocs,
} from "../hooks/useAwaitInlineDocs";
import { Posthog, TelemetryEvents } from "@tigris/common";
import { useApi } from "../hooks/useApi";
import { idvStubHandler } from "../dev/idvStubHandler";

export type DocumentVerificationStatus = "success" | "error" | "user_cancelled";

export type DocumentVerificationProps = {
  identityDocumentUrl: UrlString;
  onComplete: (status: DocumentVerificationStatus) => void;
  showIframe: boolean;
};

export const DocumentVerificationDialog = ({
  identityDocumentUrl,
  onComplete,
  showIframe,
}: DocumentVerificationProps) => {
  const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);
  const {
    startPolling,
    cancelPolling,
    result: documentVerificationResult,
    DevTools,
  } = useAwaitInlineDocs();
  const { api } = useApi();

  useEffect(() => {
    if (showIframe) {
      Posthog.capture(TelemetryEvents.onboardingInlineDocsVerificationStart);

      // This statement prevents the rest of the code in this block from being bundled for production including the `idvStubHandler` module
      if (
        !(
          import.meta.env.VITE_TIGRIS_ENV === "preview" ||
          import.meta.env.VITE_TIGRIS_ENV === "sand" ||
          import.meta.env.DEV
        )
      ) {
        return;
      }

      // When launching the iframe in lower environments, we listen for a postmessage event to dispatch a
      // stub webhook to our backend to simulate the user completing the document upload process.
      (async () => {
        const stubWebhookResult = await idvStubHandler(
          identityDocumentUrl,
          api.resolveStubIDVReceivedWebhook,
        );

        if (stubWebhookResult.isErr()) {
          // Since this is an internal utility, we should blow up here.
          throw new Error("Unable to send stub webhook.");
        }
      })();
    }
  }, [api.resolveStubIDVReceivedWebhook, identityDocumentUrl, showIframe]);

  // Once the iframe reports as loaded, start polling
  const handleIframeLoaded = useCallback(startPolling, [startPolling]);

  // Handle the user manually dismissing the iframe
  const cancelIframe = useCallback(() => {
    cancelPolling();
    onComplete("user_cancelled");
  }, [cancelPolling, onComplete]);

  useEffect(() => {
    if (documentVerificationResult === DocumentVerificationResult.ERROR) {
      setShowTimeoutDialog(false);
      onComplete("error");
    }

    if (
      documentVerificationResult ===
        DocumentVerificationResult.CLIENT_TIMEOUT &&
      !showTimeoutDialog
    ) {
      setShowTimeoutDialog(true);
    }

    if (documentVerificationResult === DocumentVerificationResult.COMPLETE) {
      Posthog.capture(TelemetryEvents.onboardingInlineDocsVerificationComplete);
      onComplete("success");
    }
  }, [documentVerificationResult, onComplete, showTimeoutDialog]);

  useEffect(() => {
    Posthog.capture(
      TelemetryEvents.onboardingInlineDocsVerificationComponentRender,
    );
  }, []);

  return (
    <>
      <Dialog
        open={showIframe}
        // Pass an empty function here to prevent closing the dialog by clicking outside of its bounds.
        onClose={() => {}}
        className="relative z-50"
      >
        <DialogBackdrop
          className="fixed inset-0 bg-neutral-400/20 duration-300 ease-out data-[closed]:opacity-0"
          transition
        />
        <DialogPanel
          className="fixed top-0 left-0 flex h-screen w-screen flex-col px-2 delay-50 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
          transition
        >
          {/* Header */}
          <div className="flex items-center justify-between p-2">
            <button
              className="flex cursor-pointer items-center gap-2 rounded-full bg-neutral-300 px-3 py-2 text-xs"
              onClick={cancelIframe}
              data-testid="DocumentVerification:cancelButton"
            >
              <span>←</span> <Logo size="sm" showText={false} />{" "}
              <span>Back to Meso</span>
            </button>
            <a
              className="block"
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.meso.network"
            >
              <FontAwesomeIcon
                icon={faLifeRing}
                className="text-neutral-700 opacity-60 dark:text-neutral-300"
              />
            </a>
          </div>

          {/* Frame content */}
          <div className="w-full flex-1 rounded-t-2xl bg-white p-2">
            <iframe
              data-testid="DocumentVerification:iframe"
              src={identityDocumentUrl}
              id="idv-frame"
              className="h-full w-full"
              frameBorder={0}
              allow="geolocation; microphone; camera;"
              onLoad={handleIframeLoaded}
            />
          </div>

          <Confirmation
            open={showTimeoutDialog}
            iconName="clock"
            body="Are you still working on verifying your docs?"
            acceptButtonText="I'm still working"
            rejectButtonText="No"
            onResult={(status) => {
              if (status === "accepted" || status === "dismissed") {
                setShowTimeoutDialog(false);
                startPolling();
              } else {
                cancelPolling();
                setShowTimeoutDialog(false);
                onComplete("user_cancelled");
              }
            }}
          />
        </DialogPanel>
        <DevTools />
      </Dialog>
    </>
  );
};
