import responseMiddleware from "../utils/middleware";
import {
  DEFAULT_ANON_USER_ID,
  Posthog,
  Sentry,
  TelemetryEvents,
  updateConsent,
} from "@tigris/common";
import { AppContext } from "./AppContext";
import {
  DEFAULT_THEME,
  ThemeName,
  UserLimits,
  isValidTheme,
  partnerThemes,
  useSafeLocalStorage,
  useCookieConsent,
} from "@tigris/mesokit";
import { GraphQLClient } from "graphql-request";
import {
  AssetAmount,
  MessageKind,
  IntegrationMode,
  AnnouncementBanner,
  CountryCodeAlpha2,
  Asset,
} from "@src/types";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SardineEnvironment } from "packages/vendor/@sardine-ai/react-js-wrapper/dist";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FiatInstrument,
  getSdk,
  InstrumentKind,
  SingleUseInstrument,
} from "../generated/sdk";
import { api as createApi } from "../api";
import { type AppContextValue, type Session, type User } from "../types";
import { useInitialization } from "@src/hooks/useInitialization";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";
import { defaultContextFn, defaultUser } from "./defaults";
import { singleUseInstrumentSchema } from "@src/utils/validation";
import { MESO_MIN_AMOUNT } from "@src/utils/constants";
import { instrumentKindToSingleUseInstrument } from "@src/utils/paymentMethod";

type AppContextProviderProps = {
  /**
   * URLSearchParams instance that will be parsed into configuration.
   */
  configurationParams: URLSearchParams;
  /** An optional callback that will be dispatched when the app context is ready. This helps components lower in the tree to defer rendering until all initialization data is resolved. */
  onReady?: () => void;
  /** An optional callback that will be dispatched when the app context encounters an error. This can be used instead of throwing an exception.   */
  onError?: () => void;
  /** Initialize the application with a mode driven by the URL. */
  mode: IntegrationMode;
};

const MESO_AUTHORIZATION_HEADER = "Authorization";

export const AppContextProvider = ({
  children,
  configurationParams,
  onReady,
  onError,
  mode,
}: PropsWithChildren<AppContextProviderProps>) => {
  const { bus, apiOrigin, configuration } = useInitialization({
    configurationParams,
    mode,
  });

  // React strict mode causes useEffect callbacks to be invoked twice. We use
  // the `sessionInitialized` ref to avoid setting a new session token from a
  // second NewSession invocation.
  const sessionInitialized = useRef(false);
  const [hasPasskey, setHasPasskey] = useSafeLocalStorage(
    "meso:hasPasskey",
    false,
  );
  const [prevFiatInstrument, setPrevFiatInstrument] = useSafeLocalStorage(
    "meso:fiatInstrument",
  );
  const [toasterId, setToasterId] = useState<number>(Date.now());
  const clearToasts = () => setToasterId(Date.now());

  const navigate = useNavigate();
  const { search } = useLocation();
  const graphQLClient = useMemo(
    () =>
      new GraphQLClient(`${apiOrigin}/query`, {
        errorPolicy: "all",
        responseMiddleware: responseMiddleware(navigate, clearToasts, search),
      }),
    [apiOrigin, navigate, search],
  );
  const api = useMemo<AppContextValue["api"]>(
    () => createApi(getSdk(graphQLClient)),
    [graphQLClient],
  );
  const onSavePreferences = useCallback(
    async (preferences: string) =>
      await api.resolveUpdateDataConsent({
        input: { dataConsent: preferences },
      }),
    [api],
  );
  const cookieConsent = useCookieConsent({
    property: "transfer-app",
    onSavePreferences,
  });
  const { CookieDialog } = cookieConsent;
  const [announcementBanner, setAnnouncementBanner] =
    useState<AnnouncementBanner>();

  const [appContextState, setAppContextState] = useState<AppContextValue>(
    () => {
      let theme = defaultUser.theme;

      if (mode === IntegrationMode.INLINE) {
        if (partnerThemes.get(configuration.partnerId)) {
          theme = partnerThemes.get(configuration.partnerId)
            ?.themeName as ThemeName;
        } else {
          theme = "inline";
        }
      }

      return {
        configuration,
        bus,
        api,
        toasterId,
        updateSession: defaultContextFn,
        updateUser: defaultContextFn,
        resetUser: defaultContextFn,
        setTransfer: defaultContextFn,
        clearToasts: defaultContextFn,
        user: { ...defaultUser, theme },
        updateConfiguration: defaultContextFn,
        isEditingAmount: false,
        isAddingCard: false,
        setIsAddingCard: defaultContextFn,
        hasPasskey,
        setHasPasskey,
        executeTransferRequestIsInFlight: false,
        setExecuteTransferRequestIsInFlight: defaultContextFn,
        browserSupportsWebAuthn: browserSupportsWebAuthn(),
        engageAmountEditor: defaultContextFn,
        closeAmountEditor: defaultContextFn,
        apiOrigin,
        appReady: false,
        mode,
        quoteLimitReached: false,
        setQuoteLimitReached: defaultContextFn,
        hasContextError: false,
        setFiatInstrument: defaultContextFn,
        cookieConsent,
      };
    },
  );

  const updateSession = useCallback(
    (session: Partial<Session>) => {
      if (session.token) {
        graphQLClient.setHeader(
          MESO_AUTHORIZATION_HEADER,
          `Bearer ${session.token}`,
        );

        Sentry.setContext("session", session);
      }

      if (
        session.riskSession &&
        session.riskSession.userId &&
        session.riskSession.userId !== DEFAULT_ANON_USER_ID
      ) {
        Posthog.identify(session.riskSession.userId);
      }

      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        session: { ...prevAppContextState.session, ...(session as Session) },
      }));
    },
    [graphQLClient],
  );

  useEffect(() => {
    if (sessionInitialized.current) return;
    sessionInitialized.current = true;

    (async () => {
      const newSessionResult = await api.resolveNewSession({
        input: {
          partnerId: appContextState.configuration.partnerId,
          network: appContextState.configuration.network,
          walletAddress: appContextState.configuration.walletAddress,
          dataConsent: JSON.stringify(cookieConsent.preferences),
        },
      });

      if (newSessionResult.isErr()) {
        return;
      }

      const newSession = newSessionResult.value;

      updateSession({
        id: newSession.id,
        token: newSession.token,
        riskSession: {
          userId: newSession.riskSession.userId,
          clientId: newSession.riskSession.clientId,
          sessionKey: newSession.riskSession.sessionKey,
          environment: newSession.riskSession.environment as SardineEnvironment,
        },
        mesoLimits: {
          min: newSession.transferMin.amount as AssetAmount,
          max: newSession.transferMax.amount as AssetAmount,
        },
        isReturningUser: newSession.isReturningUser,
        passkeysEnabled: newSession.passkeysEnabled,
        passkeyOnboardingEnabled: newSession.passkeyOnboardingEnabled,
        applepayEnabled: newSession.applepayEnabled,
        euEnabled: newSession.euEnabled,
        deviceCountry: newSession.deviceCountry ?? undefined,
      });

      if (newSession.announcementBanner) {
        setAnnouncementBanner({
          title: newSession.announcementBanner.title,
          body: newSession.announcementBanner.body,
        });
      }

      const partnerResult = await api.resolvePartnerDetails();

      if (partnerResult.isOk()) {
        setAppContextState((state) => ({
          ...state,
          partner: partnerResult.value,
        }));
      } else {
        if (onError) {
          onError();
          Sentry.captureException(partnerResult.error, {
            level: "warning",
            extra: {
              partnerResult: partnerResult.error,
              appContextState,
            },
          });
        } else {
          throw new Error(partnerResult.error);
        }
      }

      // Report "ready" across multiple channels
      // Report to partner (in embedded)
      bus?.emit({ kind: MessageKind.READY });
      // Report to internal components awaiting this callback (standalone)
      if (typeof onReady === "function") {
        onReady();
      }
      // Set `appReady` for any components watching this context
      setAppContextState((state) => ({ ...state, appReady: true }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => updateConsent(cookieConsent.preferences.performance),
    [cookieConsent.preferences.performance],
  );

  const updateUser = useCallback(
    (
      user: Partial<User>,
      supportedPaymentMethods: InstrumentKind[] = [InstrumentKind.PAYMENT_CARD],
    ) => {
      if (user.theme) {
        // For inline, we do not want to update the user's theme
        if (mode === IntegrationMode.INLINE) {
          if (partnerThemes.get(configuration.partnerId)) {
            user.theme = partnerThemes.get(configuration.partnerId)?.themeName;
          } else {
            user.theme = "inline";
          }
        } else if (mode === IntegrationMode.STANDALONE) {
          const newTheme = isValidTheme(user.theme)
            ? user.theme
            : DEFAULT_THEME;

          const currentThemeClasses = Array.from(
            document.documentElement.classList,
          ).filter((className) => className.startsWith("theme-"));

          // Side effects!
          document.documentElement.classList.remove(...currentThemeClasses);
          document.documentElement.classList.add(`theme-${newTheme}`);

          user.theme = newTheme;
        } else if (!isValidTheme(user.theme)) {
          user.theme = DEFAULT_THEME;
        } else {
          // Embedded
          const newTheme = isValidTheme(user.theme)
            ? user.theme
            : DEFAULT_THEME;

          const currentThemeClasses = Array.from(
            document.documentElement.classList,
          ).filter((className) => className.startsWith("theme-"));

          // Side effects!
          document.documentElement.classList.remove(...currentThemeClasses);
          document.documentElement.classList.add(`theme-${newTheme}`);

          user.theme = newTheme;
        }
      }

      // If the incoming user update contains `residentialAddress`, derive the user's residence country from that.
      if (user.residentialAddress) {
        const countryCode = user.residentialAddress?.countryCode;

        // Validate that `countryCode` is a valid `CountryCodeAlpha2`
        const isValidCountryCode =
          countryCode &&
          Object.values(CountryCodeAlpha2).includes(
            countryCode as CountryCodeAlpha2,
          );

        user.residenceCountry = isValidCountryCode
          ? (countryCode as CountryCodeAlpha2)
          : undefined;
      }

      setAppContextState((prevAppContextState) => {
        const updatedUser = { ...prevAppContextState.user, ...user };

        return {
          ...prevAppContextState,
          user: updatedUser,
          fiatInstrument: (() => {
            if (
              prevAppContextState.fiatInstrument &&
              singleUseInstrumentSchema.safeParse(
                prevAppContextState.fiatInstrument,
              ).success
            ) {
              // maintain the single use instrument already on the app context
              return prevAppContextState.fiatInstrument;
            } else if (
              prevAppContextState.fiatInstrument &&
              !singleUseInstrumentSchema.safeParse(
                prevAppContextState.fiatInstrument,
              ).success &&
              updatedUser.fiatInstruments?.collection.find(
                (fi) =>
                  fi.id ===
                  (prevAppContextState.fiatInstrument as FiatInstrument).id,
              )
            ) {
              // maintain the fiat instument already on the app context
              return prevAppContextState.fiatInstrument;
            } else if (prevFiatInstrument) {
              const isSingleUseInstrument =
                singleUseInstrumentSchema.safeParse(prevFiatInstrument).success;
              if (
                isSingleUseInstrument &&
                supportedPaymentMethods.find(
                  (pm) =>
                    instrumentKindToSingleUseInstrument(pm) ===
                    (prevFiatInstrument as SingleUseInstrument),
                )
              ) {
                // fallback to single use instrument successfully used on a previous transfer if it's supported
                return prevFiatInstrument as SingleUseInstrument;
              } else {
                // fallback to fiat instrument successfully used on a previous transfer if still on user
                const userFiatInstrument =
                  updatedUser.fiatInstruments?.collection?.find(
                    (fi) => fi.id === prevFiatInstrument,
                  );
                if (userFiatInstrument) {
                  return userFiatInstrument;
                }
              }
            }

            // default to first fiat instrument on the user or the first supported single use instrument
            return (
              updatedUser.fiatInstruments?.collection[0] ||
              supportedPaymentMethods
                .map((ik) => instrumentKindToSingleUseInstrument(ik))
                .find((sui) => sui)
            );
          })(),
        };
      });
    },
    [configuration.partnerId, mode, prevFiatInstrument],
  );

  const resetUser = useCallback(() => {
    setAppContextState((prevAppContextState) => ({
      ...prevAppContextState,
      user: {} as User,
    }));
  }, []);

  const setFiatInstrument = useCallback(
    (fiatInstrument: FiatInstrument | SingleUseInstrument) => {
      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        fiatInstrument,
      }));
    },
    [],
  );

  const setTransfer = useCallback(
    (transfer: Parameters<AppContextValue["setTransfer"]>[0]) => {
      setAppContextState((prevAppContextState) => {
        if (transfer && prevAppContextState.fiatInstrument) {
          const { fiatInstrument } = prevAppContextState;

          if (typeof fiatInstrument === "string") {
            if (singleUseInstrumentSchema.safeParse(fiatInstrument).success) {
              setPrevFiatInstrument(fiatInstrument);
            }
          } else if (fiatInstrument.__typename === "FiatInstrument") {
            setPrevFiatInstrument(fiatInstrument.id);
          }
        }

        return {
          ...prevAppContextState,
          transfer,
        };
      });
    },
    [setPrevFiatInstrument],
  );

  const updateConfiguration: AppContextValue["updateConfiguration"] =
    useCallback(({ sourceAmount }) => {
      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        configuration: {
          ...prevAppContextState.configuration,
          sourceAmount,
        },
      }));
    }, []);

  const engageAmountEditor = useCallback<AppContextValue["engageAmountEditor"]>(
    (sourceAction) => {
      Posthog.capture(TelemetryEvents.amountEditorEngage, {
        sourceAction,
      });

      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        isEditingAmount: true,
      }));
    },
    [],
  );

  const closeAmountEditor = useCallback<
    AppContextValue["closeAmountEditor"]
  >(() => {
    setAppContextState((prevAppContextState) => ({
      ...prevAppContextState,
      isEditingAmount: false,
    }));
  }, []);

  const setIsAddingCard = useCallback<AppContextValue["setIsAddingCard"]>(
    (isAddingCard: boolean) => {
      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        isAddingCard,
      }));
    },
    [],
  );

  const setExecuteTransferRequestIsInFlight = useCallback<
    AppContextValue["setExecuteTransferRequestIsInFlight"]
  >((inFlight: boolean) => {
    setAppContextState((prevAppContextState) => ({
      ...prevAppContextState,
      executeTransferRequestIsInFlight: inFlight,
    }));
  }, []);

  const setQuoteLimitReached = useCallback(() => {
    setAppContextState((prevAppContextState) => ({
      ...prevAppContextState,
      quoteLimitReached: true,
    }));
  }, []);

  const userLimits = useMemo<UserLimits | undefined>(() => {
    if (!appContextState.session || !appContextState.user.limits) return;

    const session = appContextState.session;
    const userLimits = appContextState.user.limits;
    const sourceAmount = appContextState.configuration.sourceAmount;

    const mesoMinimum = session.mesoLimits.min
      ? Number(session.mesoLimits.min)
      : MESO_MIN_AMOUNT;

    const monthlyAmountUsed = {
      ...userLimits?.monthlyCashInUsed,
      amount: Number(userLimits?.monthlyCashInUsed.amount),
    };

    const monthlyMaximumAmount = {
      ...userLimits?.monthlyMax,
      amount: Number(userLimits?.monthlyMax?.amount),
    };

    const monthlyAmountAvailable = {
      ...userLimits?.monthlyCashInAvailable,
      amount: Number(userLimits?.monthlyCashInAvailable.amount),
    };

    const requestedAmount = Number(sourceAmount);
    const percentUsed = monthlyAmountUsed.amount / monthlyMaximumAmount.amount;

    return {
      mesoMinimum,
      monthlyAmountAvailable,
      monthlyAmountUsed,
      monthlyMaximumAmount,

      // Computed properties
      percentUsed,
      requestedAmountExceedsLimit:
        requestedAmount > monthlyAmountAvailable.amount,
      editable: monthlyAmountAvailable.amount - mesoMinimum >= mesoMinimum,
      limitReached: monthlyAmountUsed.amount >= monthlyMaximumAmount.amount,
      approachingLimit: percentUsed > 0.8,
      currencyCode:
        appContextState.user.residenceCountry === CountryCodeAlpha2.US
          ? Asset.USD
          : Asset.EUR,
    };
  }, [
    appContextState.configuration.sourceAmount,
    appContextState.session,
    appContextState.user.limits,
    appContextState.user.residenceCountry,
  ]);

  const contextValue = useMemo(() => {
    return {
      ...appContextState,
      updateSession,
      updateUser,
      resetUser,
      setTransfer,
      toasterId,
      clearToasts,
      updateConfiguration,
      engageAmountEditor,
      closeAmountEditor,
      setIsAddingCard,
      hasPasskey,
      setHasPasskey,
      setExecuteTransferRequestIsInFlight,
      setQuoteLimitReached,
      announcementBanner,
      setFiatInstrument,
      userLimits,
      cookieConsent,
    };
  }, [
    appContextState,
    updateSession,
    updateUser,
    resetUser,
    setTransfer,
    toasterId,
    updateConfiguration,
    engageAmountEditor,
    closeAmountEditor,
    setIsAddingCard,
    hasPasskey,
    setHasPasskey,
    setExecuteTransferRequestIsInFlight,
    setQuoteLimitReached,
    announcementBanner,
    setFiatInstrument,
    userLimits,
    cookieConsent,
  ]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
      <CookieDialog />
    </AppContext.Provider>
  );
};
export { AppContext };
