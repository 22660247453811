import {
  ComponentPropsWithoutRef,
  FormEventHandler,
  useCallback,
  useState,
} from "react";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import {
  Button,
  LoadingInterstitial,
  PaymentMethodSelector,
} from "@tigris/mesokit";
import { InstrumentKind } from "../generated/sdk";
import { OnboardingAppRenderContext, Routes, UserStatus } from "../types";
import { getNextOnboardingStep } from "../utils/nextOnboardingStep";
import { toast } from "sonner";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { useRouter } from "../hooks/useRouter";
import { TelemetryEvents, Posthog } from "@tigris/common";
import { useActivateUser } from "../hooks/useActivateUser";

type OnPaymentMethodSelected = ComponentPropsWithoutRef<
  typeof PaymentMethodSelector
>["onPaymentMethodSelected"];
type PaymentMethod = Parameters<OnPaymentMethodSelected>[0];

const TOAST_ID = "SelectPaymentMethod";

export const SelectPaymentMethod = () => {
  const { navigate, enableNavigation, disableNavigation } = useRouter();
  const {
    api: { resolveSkipFiatInstrument },
    session,
  } = useApi();
  const {
    updateUser,
    configuration,
    returnToTransfer,
    renderContext,
    transferEligibleForScamWarning,
  } = useOnboarding();
  const { activateUser, isActivatingUser } = useActivateUser();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadingInterstitial, setShowLoadingInterstitial] = useState(false);

  const handleActivateUserError = useCallback(
    (userActivationStartTime: number) => {
      // Collect metrics
      const duration = performance.now() - userActivationStartTime;
      const sessionId = session?.id;
      Posthog.capture(TelemetryEvents.userActivationComplete, {
        status: "error",
        sessionId,
        duration,
      });

      enableNavigation();

      if (renderContext === OnboardingAppRenderContext.EMBEDDED_IN_TRANSFER) {
        returnToTransfer("onboardingTerminated");
      } else {
        navigate(Routes.ManualReview);
      }
    },
    [enableNavigation, navigate, renderContext, returnToTransfer, session?.id],
  );

  const handleActivateUserSuccess = useCallback(
    (userStatus: UserStatus, userActivationStartTime: number) => {
      // Collect metrics
      const duration = performance.now() - userActivationStartTime;
      const sessionId = session?.id;

      Posthog.capture(TelemetryEvents.userActivationComplete, {
        status: userStatus,
        sessionId,
        duration,
      });

      enableNavigation();

      if (userStatus === UserStatus.ACTIVE) {
        navigate(Routes.Summary);
      } else if (userStatus === UserStatus.IN_REVIEW) {
        // Routing to /manual-review will handle the `returnToTransfer` call
        navigate(Routes.ManualReview, { userStatus });
      } else {
        returnToTransfer("onboardingTerminated");
      }
    },
    [enableNavigation, navigate, returnToTransfer, session?.id],
  );

  const handleSelectPaymentMethod = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();
      Posthog.capture(TelemetryEvents.onboardingPaymentMethodSelect, {
        paymentMethod,
      });

      if (paymentMethod === InstrumentKind.PAYMENT_CARD) {
        navigate(Routes.AddPaymentCard);
      } else if (paymentMethod === InstrumentKind.APPLE_PAY) {
        setIsLoading(true);
        const skipFiatInstrumentResult = await resolveSkipFiatInstrument();
        setIsLoading(false);

        if (skipFiatInstrumentResult.isErr()) {
          toast.error(skipFiatInstrumentResult.error, { id: TOAST_ID });
        } else {
          const updatedUser = skipFiatInstrumentResult.value.user;
          updateUser({
            onboardingFiatInstrumentSkipped:
              updatedUser.onboardingFiatInstrumentSkipped,
            status: updatedUser.status,
          });

          const nextStep = getNextOnboardingStep({
            profileStatus: skipFiatInstrumentResult.value.skipFiatInstrument,
            supportedPaymentMethods: configuration.supportedPaymentMethods,
            user: updatedUser,
            selectedPaymentMethod: paymentMethod,
            transferEligibleForScamWarning,
          });

          if (nextStep.isErr()) {
            toast.error(nextStep.error, { id: TOAST_ID });
            return;
          }

          // This means the user has completed all profile status steps and we can begin activation
          if (nextStep.value === Routes.Summary) {
            // Collect metrics
            const userActivationStartTime = performance.now();

            Posthog.capture(TelemetryEvents.userActivationStart, {
              sessionId: session?.id,
            });

            setShowLoadingInterstitial(true);
            disableNavigation();

            const activateUserResult = await activateUser();
            if (activateUserResult.isErr()) {
              handleActivateUserError(userActivationStartTime);
            } else {
              handleActivateUserSuccess(
                activateUserResult.value,
                userActivationStartTime,
              );
            }
          } else {
            navigate(nextStep.value);
          }
        }
      }
    },
    [
      activateUser,
      configuration.supportedPaymentMethods,
      disableNavigation,
      handleActivateUserError,
      handleActivateUserSuccess,
      navigate,
      paymentMethod,
      resolveSkipFiatInstrument,
      session?.id,
      updateUser,
      transferEligibleForScamWarning,
    ],
  );

  return (
    <AnimationContainer>
      {showLoadingInterstitial ? (
        <LoadingInterstitial mode="loading" />
      ) : (
        <form
          id="SelectPaymentMethod"
          data-testid="SelectPaymentMethod"
          name="SelectPaymentMethod"
          onSubmit={handleSelectPaymentMethod}
          className="onboarding-inner-content"
        >
          <Heading
            title="Payment Method"
            subtitle="Select Apple Pay or debit card."
          />
          <PaymentMethodSelector
            supportedPaymentMethods={configuration.supportedPaymentMethods}
            onPaymentMethodSelected={(paymentMethod: PaymentMethod) =>
              setPaymentMethod(paymentMethod)
            }
          />
          <div className="onboarding-footer">
            <Button
              key="SelectPaymentMethod:button"
              disabled={!paymentMethod || isLoading || isActivatingUser}
              isLoading={isLoading}
              type="submit"
            >
              Continue
            </Button>
          </div>
        </form>
      )}
    </AnimationContainer>
  );
};
