import {
  Button,
  Title,
  Text,
  CobrandingLogo,
  ErrorMessages,
  PartnerLogo,
} from "@tigris/mesokit";
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "sonner";
import AnimationContainer from "./AnimationContainer";
import { useRouter } from "../hooks/useRouter";
import { Routes } from "../types";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { AnimatePresence, motion } from "framer-motion";
import { truncateAddress } from "@tigris/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/pro-solid-svg-icons";

const TOAST_ID = "Landing";
const FORM_ID = "OnboardingLanding";

export const Landing = () => {
  const { navigate } = useRouter();
  const { api, session } = useApi();
  const { user, configuration, returnToTransfer } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const initiatedOnboarding = useRef(false);
  const [startedOnboarding, setStartedOnboarding] = useState(false);
  const [showFullAddress, setShowFullAddress] = useState(false);

  const startOnboarding = useCallback(async () => {
    const initialAttempt = !initiatedOnboarding.current;
    initiatedOnboarding.current = true;
    setIsLoading(true);

    const startOnboardingResult = await api.resolveStartOnboarding({
      input: {
        network: configuration.network,
        walletAddress: configuration.walletAddress,
      },
    });

    setIsLoading(false);

    if (startOnboardingResult.isErr()) {
      if (!initialAttempt) {
        toast.error(ErrorMessages.startOnboarding.UNABLE_TO_START_ONBOARDING, {
          id: TOAST_ID,
        });
      }

      return false;
    } else if ("loginWithEmailAndPassword" in startOnboardingResult.value) {
      toast(
        <div className="flex flex-col justify-center gap-2">
          {ErrorMessages.agreements.DUPLICATE_WALLET_ERROR}
          <Button
            containerClassName="h-8"
            onClick={() => {
              // For inline/embedded: Close onboarding window and navigate to email/password login
              // For standalone: Navigate to email/password login
              returnToTransfer("returnToLogin");
            }}
          >
            Log In
          </Button>
        </div>,
        { id: TOAST_ID },
      );
    } else {
      setStartedOnboarding(true);
      return true;
    }
  }, [
    api,
    configuration.network,
    configuration.walletAddress,
    returnToTransfer,
  ]);

  useEffect(() => {
    if (!initiatedOnboarding.current && user.status === undefined)
      startOnboarding();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();
      if (
        !startedOnboarding &&
        user.status === undefined &&
        !(await startOnboarding())
      ) {
        return;
      }

      setIsLoading(true);

      if (session?.euEnabled) {
        navigate(Routes.DeclareResidency);
      } else {
        navigate(Routes.CreateAccount);
      }
    },
    [
      navigate,
      session?.euEnabled,
      startOnboarding,
      startedOnboarding,
      user.status,
    ],
  );

  return (
    <AnimationContainer>
      <form
        name={FORM_ID}
        id={FORM_ID}
        onSubmit={handleSubmit}
        className="onboarding-inner-content"
        data-testid={FORM_ID}
      >
        <div
          data-testid="brandingHero"
          className="my-auto flex flex-col items-center justify-center"
        >
          <motion.div layoutId="CoBranding">
            <CobrandingLogo
              size="lg"
              displayName={configuration.partner?.displayName}
              logoUri={configuration.partner?.logoUri}
            />
          </motion.div>
          <Title.Medium bold className="mt-4">
            Get started with Meso
          </Title.Medium>
          <Text className="text-center">
            {configuration.partner?.displayName
              ? `${configuration.partner.displayName} uses Meso to instantly transfer money between your wallet and bank.`
              : "Instantly transfer money between your wallet and bank with Meso."}
          </Text>
        </div>

        {/* Bottom content */}

        {configuration.walletAddress && (
          <div
            className="dark:border-ts-subtle mt-4 mb-2 overflow-hidden rounded-xl border border-neutral-700/10 p-3 shadow-lg"
            data-testid="Landing:walletDetails"
          >
            <div className="mb-2 flex flex-col">
              <div className="flex items-center gap-1">
                <PartnerLogo
                  size="xs"
                  displayName={configuration.partner?.displayName}
                  logoUri={configuration.partner?.logoUri}
                />

                <Text className="font-bold">Wallet Address</Text>
              </div>
              <div className="flex items-center justify-between gap-2 text-sm font-medium text-neutral-400">
                <div className="text-wrap break-all">
                  <AnimatePresence mode="wait">
                    {showFullAddress ? (
                      <motion.div
                        key="FullAddress"
                        layout
                        initial={{ opacity: 0, height: "1rem" }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: "1rem" }}
                      >
                        {configuration.walletAddress}
                      </motion.div>
                    ) : (
                      <motion.div
                        key="TruncatedAddress"
                        layout
                        initial={{ opacity: 0, height: "1rem" }}
                        animate={{ opacity: 1, height: "1rem" }}
                        exit={{ opacity: 0, height: "1rem" }}
                      >
                        {truncateAddress(
                          configuration.walletAddress,
                          configuration.network,
                          12,
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircleChevronRight}
                    onClick={() => setShowFullAddress(!showFullAddress)}
                    className={`${
                      showFullAddress ? "rotate-90" : "rotate-0"
                    } mr-1 ml-auto cursor-pointer opacity-60 transition-transform`}
                  />
                </div>
              </div>
            </div>
            <div className="text-[11px] leading-tight tracking-tighter text-neutral-400">
              This address will be linked to your Meso account. You are
              responsible for ensuring this is the correct address.
            </div>
          </div>
        )}

        <Button
          key="Landing:button"
          type="submit"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Continue
        </Button>
      </form>
    </AnimationContainer>
  );
};
