import {
  cloneElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { AnimatePresence, motion, Variants } from "framer-motion";
import {
  LANDING_AND_TRANSFER_SHEET_STANDALONE_HEIGHT,
  MESO_MAX_AMOUNT,
  MESO_MIN_AMOUNT,
  Routes,
} from "@utils/constants";
import { twMerge } from "tailwind-merge";
import { Toast } from "../Toast";
import { motionVariants, spring } from "@src/utils/animation";
import { AppContextValue, CashInQuote, OutletContext } from "@src/types";
import { AppContext } from "../../contexts/AppContextProvider";
import {
  CardHero,
  LineItems,
  ErrorMessages,
  EnvironmentBanner,
  BuyLimit,
  Text,
} from "@tigris/mesokit";
import { TransferKind, TransferStatus } from "@src/generated/sdk";
import { useQuote, UseQuoteConfiguration } from "@src/hooks/useQuote";
import { TransferStatusIndicator } from "../TransferStatusIndicator";
import { TRANSFER_SUCCESS_STATUSES } from "@src/hooks/useTransfer";
import { AmountEditor } from "../AmountEditor";
import { StandaloneHeader } from "./StandaloneHeader";
import { QuoteLimitErrorCode } from "@src/api";
import { StandaloneDevControls } from "@src/dev/StandaloneDevControls";
import { AnnouncementBanner } from "../AnnouncementBanner";
import { toast } from "sonner";
import { useApplePayContext } from "@src/hooks/useApplePayContext";
import { useOnboarding } from "@src/hooks/useOnboarding";
import { AddDebitCard } from "../AddDebitCard";
import { Posthog, TelemetryEvents } from "@tigris/common";

const TOAST_ID = "StandaloneLayout";

export const StandaloneLayout = () => {
  const { pathname } = useLocation();
  const isOnboardingRoute = useMemo(
    () => pathname.startsWith("/onboarding"),
    [pathname],
  );
  const isAuxiliaryRoute = useMemo(
    () =>
      pathname === Routes.TransferUnavailable ||
      pathname === Routes.ActivateUser,
    [pathname],
  );
  const {
    configuration: { network, destinationAsset, walletAddress: address },
    user,
    fiatInstrument,
    setFiatInstrument,
    userLimits,
    transfer,
    engageAmountEditor,
    closeAmountEditor,
    isEditingAmount,
    session,
    isAddingCard,
    setIsAddingCard,
    api: { resolveRemoveFiatInstrument },
    updateUser,
    executeTransferRequestIsInFlight,
  } = useContext<AppContextValue>(AppContext);
  const { theme, fiatInstruments } = user;
  const { applePayEnabled, applePayCanceled } = useApplePayContext();
  const [amountEditorErrorMessage, setAmountEditorErrorMessage] =
    useState<string>();

  const allowAmountEditing = useMemo(() => {
    const isRouteThatAllowsEditing =
      pathname === Routes.TransferSheet || pathname === Routes.LandingSheet;

    if (userLimits && isRouteThatAllowsEditing) {
      if (
        userLimits.percentUsed >= 1 ||
        (userLimits.requestedAmountExceedsLimit && !userLimits.editable)
      ) {
        return false;
      } else {
        return isRouteThatAllowsEditing;
      }
    }

    return false;
  }, [pathname, userLimits]);
  const showLimits = useMemo<boolean>(() => {
    if (!userLimits) return false;
    if (transfer && transfer.status) return false;
    if (userLimits && !userLimits.limitReached && userLimits.approachingLimit)
      return true;

    return false;
  }, [userLimits, transfer]);

  useEffect(() => {
    const toastId = "requestedAmountExceedsLimit";

    if (
      userLimits &&
      userLimits.requestedAmountExceedsLimit &&
      !userLimits.editable
    ) {
      Posthog.capture(TelemetryEvents.quoteRequestedAmountExceedsLimit, {
        limits: userLimits,
        sessionId: session?.id ?? "unknown",
      });

      toast.info(ErrorMessages.quote.REQUESTED_AMOUNT_EXCEEDS_LIMIT, {
        id: toastId,
      });
    } else {
      toast.dismiss(toastId);
    }
  }, [allowAmountEditing, userLimits, session?.id]);

  // This handler is called whenever there is a "limit error" fetching a quote
  const handleQuoteLimitError = useCallback<
    Required<UseQuoteConfiguration>["onLimitError"]
  >(
    (code?: QuoteLimitErrorCode) => {
      if (code === "below_min_xfer") {
        setAmountEditorErrorMessage(
          ErrorMessages.quote.minLimitError(
            userLimits?.mesoMinimum ?? MESO_MIN_AMOUNT,
          ),
        );
      }

      // Polling is stopped by the useQuote hook when this callback is dispatched.
      engageAmountEditor("auto");
    },
    [engageAmountEditor, userLimits?.mesoMinimum],
  );
  const quoteHook = useQuote({ onLimitError: handleQuoteLimitError });
  const { quote, stop: stopPolling, restart: restartPolling } = quoteHook;
  const outlet = useOutlet({ useQuote: quoteHook } satisfies OutletContext);
  const { supportedPaymentMethods } = useOnboarding();

  const handleAmountEditorResolved = useCallback(() => {
    closeAmountEditor();
    restartPolling();
    setAmountEditorErrorMessage(undefined);
  }, [restartPolling, closeAmountEditor]);

  const handleInitializeAmountEditor = useCallback(
    (sourceAction: Parameters<AppContextValue["engageAmountEditor"]>[0]) => {
      stopPolling();
      engageAmountEditor(sourceAction);
      setAmountEditorErrorMessage(undefined);
    },
    [engageAmountEditor, stopPolling],
  );

  const showChromatic = useMemo(() => {
    return (
      !!transfer?.status &&
      [
        TransferStatus.APPROVED,
        TransferStatus.COMPLETE,
        TransferStatus.EXECUTING,
      ].includes(transfer.status)
    );
  }, [transfer?.status]);

  const showChromaticFull = useMemo(
    () =>
      !!transfer?.status && TRANSFER_SUCCESS_STATUSES.includes(transfer.status),
    [transfer?.status],
  );

  const className = twMerge(
    "card-container",
    showChromatic
      ? "shadow-none transition-shadow overflow-visible"
      : "overflow-y-auto overflow-x-hidden sm:overflow-hidden flex flex-col",
  );

  const animationVariants: Record<string, Variants> = useMemo(() => {
    const height =
      isOnboardingRoute || isAddingCard
        ? "100%"
        : LANDING_AND_TRANSFER_SHEET_STANDALONE_HEIGHT;

    return {
      mainContainer: {
        initial: { scale: 0.9, y: -24, height },
        animate: { scale: 1, y: 0, height },
        exit: { scale: 1.2, y: -24, opacity: 0, height },
      },
      fadeInOut: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
      },
    };
  }, [isAddingCard, isOnboardingRoute]);

  const amountEditorMinMax = useMemo(() => {
    let maximumAmount = 0;

    if (userLimits?.monthlyAmountAvailable) {
      maximumAmount = userLimits?.monthlyAmountAvailable.amount;
    } else if (session?.mesoLimits.max) {
      maximumAmount = Number(session?.mesoLimits.max);
    } else {
      maximumAmount = MESO_MAX_AMOUNT;
    }

    return {
      minimumAmount: userLimits?.mesoMinimum ?? MESO_MIN_AMOUNT,
      maximumAmount,
    };
  }, [
    session?.mesoLimits.max,
    userLimits?.mesoMinimum,
    userLimits?.monthlyAmountAvailable,
  ]);

  const shouldRenderAmountEditor = useMemo(() => {
    if (!userLimits) {
      return isEditingAmount;
    }

    if (isEditingAmount && userLimits.editable) {
      return true;
    }

    return false;
  }, [isEditingAmount, userLimits]);

  const onRemoveFiatInstrument = useCallback(
    async (id: string) => {
      const result = await resolveRemoveFiatInstrument({ input: { id } });
      if (
        result.isOk() &&
        result.value.user.fiatInstruments?.__typename === "FiatInstruments" &&
        result.value.user.fiatInstruments
      ) {
        const fiatInstruments = result.value.user.fiatInstruments;
        updateUser({ fiatInstruments }, supportedPaymentMethods);
      } else if (result.isErr()) {
        toast?.error(result.error, { id: TOAST_ID });
      }
    },
    [resolveRemoveFiatInstrument, supportedPaymentMethods, updateUser],
  );

  return (
    <div className="root-layout standalone-layout flex overflow-y-auto bg-neutral-100 dark:bg-neutral-900">
      <div className="relative mx-auto flex h-full w-full flex-col gap-4 pt-12 sm:m-auto sm:h-[40rem] sm:w-[22rem] sm:pt-0">
        <Toast key={pathname} />

        {isOnboardingRoute ? (
          <div>{outlet}</div>
        ) : isAuxiliaryRoute ? (
          <div className="onboarding-container shadow-ts-card rounded-ts-card bg-white text-neutral-800 dark:bg-neutral-800 dark:text-white">
            {outlet}
          </div>
        ) : (
          <>
            <AnnouncementBanner />
            <StandaloneHeader
              handleEditAmount={() =>
                handleInitializeAmountEditor("header_button_click")
              }
              allowAmountEditing={allowAmountEditing}
            />
            <AnimatePresence mode="wait">
              <motion.div
                key="StandaloneCardContainer"
                variants={animationVariants.mainContainer}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={spring}
                className={className}
                layout="size"
              >
                <AnimatePresence mode="wait">
                  <motion.div
                    variants={animationVariants.fadeInOut}
                    initial="initial"
                    animate={{
                      opacity: 1,
                      transition: { delay: 0.05 },
                    }}
                    exit="exit"
                    key="StandaloneWrapper"
                    className="h-full"
                  >
                    <AnimatePresence mode="wait">
                      {shouldRenderAmountEditor && (
                        <AmountEditor
                          key="AmountEditor"
                          onCancel={handleAmountEditorResolved}
                          onComplete={handleAmountEditorResolved}
                          minimumAmount={amountEditorMinMax.minimumAmount}
                          maximumAmount={amountEditorMinMax.maximumAmount}
                          errorMessage={amountEditorErrorMessage}
                        />
                      )}
                      {isAddingCard && <AddDebitCard />}
                      {!shouldRenderAmountEditor && !isAddingCard && (
                        <motion.div
                          key="StandaloneTransferContentWrapper"
                          variants={animationVariants.fadeInOut}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          className="flex h-full flex-col"
                          layout="size"
                        >
                          <CardHero
                            disabled={userLimits?.limitReached}
                            theme={theme}
                            asset={destinationAsset}
                            amount={quote?.destination?.amount}
                            network={network}
                            walletAddress={address}
                            fiatInstrument={fiatInstrument}
                            fiatInstruments={fiatInstruments}
                            setFiatInstrument={setFiatInstrument}
                            setIsAddingCard={setIsAddingCard}
                            transferKind={TransferKind.CASH_IN}
                            applePayEnabled={applePayEnabled}
                            applePayCanceled={applePayCanceled}
                            editable={
                              allowAmountEditing &&
                              !!!transfer?.status &&
                              !executeTransferRequestIsInFlight
                            }
                            onEditAmount={() =>
                              handleInitializeAmountEditor("amount_click")
                            }
                            canShowPopover={
                              !!!transfer?.status &&
                              !executeTransferRequestIsInFlight
                            }
                            // If the user has attempted a transfer or the user has reached their limit, don't render this in the card hero
                            limits={
                              showLimits && userLimits ? userLimits : undefined
                            }
                            authenticated={!!user.id}
                            onRemoveFiatInstrument={onRemoveFiatInstrument}
                          />
                          {transfer?.status && (
                            <TransferStatusIndicator
                              className="absolute top-0 right-0 bottom-0 left-0 m-auto h-[15%] text-center text-sm font-semibold"
                              status={transfer.status}
                              bloom={false}
                            />
                          )}
                          <div className="quote-container">
                            <AnimatePresence mode="wait">
                              {userLimits && userLimits.limitReached ? (
                                <motion.div
                                  key="BuyLimitReached"
                                  variants={motionVariants.fadeIn}
                                  className="flex h-full flex-col justify-between"
                                >
                                  <>
                                    <BuyLimit limits={userLimits} />
                                    <Text>
                                      You have reached your rolling 30-day
                                      buying limit.{" "}
                                      <a
                                        className="font-medium underline"
                                        href="https://support.meso.network/hc/en-us/articles/17053929093531-Fees-Limits"
                                      >
                                        Learn more about buy limits
                                      </a>
                                      .
                                    </Text>
                                  </>
                                </motion.div>
                              ) : (
                                <motion.div
                                  key="LineItems"
                                  variants={motionVariants.fadeIn}
                                >
                                  <LineItems
                                    expanded={true}
                                    key="TransferSheet2FaQuoteLineItems"
                                    exchangeRate={quote?.exchangeRate}
                                    mesoFee={
                                      (quote as CashInQuote)?.mesoFeePresented
                                    }
                                    mesoFeeWaived={
                                      (quote as CashInQuote)?.mesoFeeWaived
                                    }
                                    networkFee={
                                      (quote as CashInQuote)
                                        ?.networkFeePresented
                                    }
                                    networkFeeWaived={
                                      (quote as CashInQuote)?.networkFeeWaived
                                    }
                                    subtotal={
                                      (quote as CashInQuote)
                                        ?.sourceSubtotalPresented
                                    }
                                    total={
                                      (quote as CashInQuote)
                                        ?.sourceTotalPresented
                                    }
                                    transferKind={TransferKind.CASH_IN}
                                  />
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>

                  {showChromatic && (
                    <motion.div
                      key="chromatic-background"
                      initial={{
                        opacity: 0,
                        y: "-50%",
                        scale: 0.5,
                      }}
                      animate={{
                        opacity: showChromaticFull ? 1 : 0.5,
                        y: "-50%",
                        scale: showChromaticFull ? 1 : 0.8,
                        transition: { duration: 0.5, ...spring },
                      }}
                      exit={{ opacity: 0, scale: 0.7 }}
                      className="chromatic-background-wrapper"
                    >
                      <motion.div
                        initial={{
                          rotate: 0,
                        }}
                        animate={{
                          rotate: 360,
                          transition: {
                            repeat: Infinity,
                            duration: 8,
                            repeatType: "loop",
                            ease: "linear",
                          },
                        }}
                        className="chromatic-background-inner"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
              isAddingCard
            </AnimatePresence>
            {outlet && !userLimits?.limitReached && !isAddingCard && (
              <div className="bottom-0 mt-auto w-full sm:absolute">
                <AnimatePresence mode="wait">
                  {cloneElement(outlet, { key: pathname })}
                </AnimatePresence>
              </div>
            )}
          </>
        )}
      </div>

      <StandaloneDevControls />
      <div className="fixed top-4 left-0 flex w-full justify-center">
        <EnvironmentBanner />
      </div>
    </div>
  );
};
