import { Button, InlineFormInput } from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint } from "@fortawesome/pro-light-svg-icons";
import {
  AnimationSequence,
  motion,
  useAnimate,
  usePresence,
} from "framer-motion";
import { defaultFormField, emailSchema } from "@tigris/common";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { spring } from "@src/utils/animation";
import { INNER_CARD_HORIZONTAL_ANIMATION_DISTANCE } from "@src/utils/constants";
import { FormField, LoginLandingView } from "@src/types";
import { AppContext } from "@src/contexts/AppContextProvider";

type FormState = {
  isValid: boolean;
  fields: { email: FormField };
};

const FORM_ID = "LandingSheetLoginEntry:email";
const INPUT_ID = "emailInput";

export const LandingSheetLoginEntryEmail = ({
  onComplete,
  previousEmailAddress = "",
  onLogInWithPasskey,
  from,
  onSignupRequest,
  allowSignUp,
  isLoading,
}: {
  onComplete: (email: string) => void;
  previousEmailAddress: string;
  onSignupRequest: () => void;
  /** If we detect a returning user, we should not allow them to attempt to sign up again. */
  allowSignUp: boolean;
  from: LoginLandingView;
  onLogInWithPasskey: (useBrowserAutofill?: boolean) => Promise<void>;
  isLoading: boolean;
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();
  const { browserSupportsWebAuthn, session } = useContext(AppContext);
  const [formState, setFormState] = useState<FormState>(() => {
    if (previousEmailAddress === "") {
      return {
        isValid: false,
        fields: { email: defaultFormField(previousEmailAddress) },
      };
    }

    return {
      isValid: true,
      fields: {
        email: {
          isDirty: true,
          isTouched: true,
          isValid: true,
          value: previousEmailAddress,
        },
      },
    };
  });
  const emailInput = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      if (!formState.isValid) return;

      onComplete(formState.fields.email.value);
    },
    [formState.fields.email.value, formState.isValid, onComplete],
  );

  const renderFieldAsValid = useCallback((): boolean => {
    const field = formState.fields.email;

    if (!field.isTouched || field.isValid) {
      return true;
    }

    if (field.isTouched && field.isDirty) {
      return field.isValid;
    }

    return true;
  }, [formState.fields.email]);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setFormState((previousState) => {
        const previousValue = previousState.fields.email.value;
        const newValue = event.target.value;

        const isDirty = newValue !== previousValue;

        let isValid = false;

        const result = emailSchema.safeParse(newValue);
        isValid = result.success;

        return {
          ...previousState,
          isValid,
          fields: {
            email: {
              ...previousState.fields.email,
              value: newValue,
              isValid,
              isDirty,
            },
          },
        };
      });
    },
    [setFormState],
  );

  const handleInputBlur = useCallback(() => {
    setFormState((previousState) => ({
      ...previousState,
      isTouched: true,
      isValid: previousState.fields.email.isValid,
      isDirty: previousState.fields.email.isDirty,
      fields: {
        email: {
          ...previousState.fields.email,
          isTouched: true,
        },
      },
    }));
  }, []);

  const focusInput = useCallback(() => {
    emailInput.current?.focus();
  }, []);

  // Animate in
  useEffect(() => {
    const sequence: AnimationSequence = [
      [scope.current, { opacity: 1 }],
      [scope.current, { x: 0 }, { at: 0.05 }],
      [".login-input-label", { y: 0, opacity: 1 }, { at: 0.2 }],
      [".login-input-accessory", { y: 0, opacity: 0.6 }, { at: 0.3 }],
    ];
    animate(sequence).then(focusInput);
  }, [animate, focusInput, scope]);

  // Exit
  useEffect(() => {
    const sequence: AnimationSequence = [
      [scope.current, { opacity: 0 }],
      [".login-input-label", { y: 10, opacity: 0 }],
      [".login-input-accessory", { y: 10, opacity: 0 }],
      [
        scope.current,
        { x: -INNER_CARD_HORIZONTAL_ANIMATION_DISTANCE },
        { at: 0.05 },
      ],
    ];

    if (!isPresent) {
      animate(sequence).then(safeToRemove);
    }
  }, [animate, isPresent, safeToRemove, scope]);

  return (
    <motion.div
      key={`${FORM_ID}:motion`}
      data-testid={`${FORM_ID}:motion`}
      initial={{
        x:
          from === "password"
            ? -INNER_CARD_HORIZONTAL_ANIMATION_DISTANCE
            : INNER_CARD_HORIZONTAL_ANIMATION_DISTANCE,
        opacity: 0,
      }}
      transition={{ ...spring }}
      ref={scope}
    >
      <form
        id={FORM_ID}
        name={FORM_ID}
        data-testid={FORM_ID}
        onSubmit={handleSubmit}
        noValidate
      >
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          className="login-input-label mb-2 flex justify-between text-xs font-bold text-neutral-900 dark:text-white"
        >
          <label htmlFor={INPUT_ID} className="cursor-pointer">
            Email Address
          </label>
          {allowSignUp && (
            <motion.div
              initial={{ y: 10, opacity: 0 }}
              className="login-input-accessory group cursor-pointer opacity-60"
              onClick={onSignupRequest}
            >
              Sign Up{" "}
              <span className="inline-flex transition-transform group-hover:translate-x-0.5">
                →
              </span>
            </motion.div>
          )}
        </motion.div>
        <div className="flex items-center gap-2">
          <InlineFormInput
            input={{
              name: INPUT_ID,
              type: "email",
              placeholder: "Your email address",
              value: formState.fields.email.value,
              onChange: handleInputChange,
              onBlur: handleInputBlur,
              isValid: renderFieldAsValid(),
              maxLength: 255,
              "data-testid": `${FORM_ID}:${INPUT_ID}`,
              autoComplete: "webauthn",
            }}
            ref={emailInput}
            button={{
              primary: true,
              disabled: !formState.isValid || isLoading,
              "data-testid": `${FORM_ID}:button`,
            }}
          />
          {session?.passkeysEnabled && browserSupportsWebAuthn && (
            <Button
              data-testid="LandingSheetLoginEntry:email:passkeyButton"
              className="flex w-[50px] items-center justify-center p-3"
              onClick={() => onLogInWithPasskey()}
              isLoading={isLoading}
              disabled={isLoading}
              primary={false}
            >
              {!isLoading && (
                <FontAwesomeIcon icon={faFingerprint} className="h-6 w-6" />
              )}
            </Button>
          )}
        </div>
      </form>
    </motion.div>
  );
};
