import { ThemeName, themeCollection } from "@tigris/mesokit";
import { Fragment, useState } from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { Transition } from "@headlessui/react";
import { useOnboarding } from "../hooks/useOnboarding";
import { Posthog, TelemetryEvents } from "@tigris/common";

const ThemeIcon = ({
  currentTheme,
  theme,
  onThemeChange,
}: {
  currentTheme: ThemeName;
  theme: ThemeName;
  onThemeChange: (theme: ThemeName) => void;
}) => {
  const [hover, setHover] = useState(false);
  const wrapper = twMerge(
    "h-12 w-12 rounded-[64px] active:scale-95 ring-4 cursor-pointer ring-transparent relative flex items-center justify-center transition-all",
    // This has to remain because we need to render all frames
    `theme-${theme}`,
    theme === currentTheme && "ring-blue-500 dark:ring-white",
  );
  const themeIconClasses = twMerge(
    "w-full h-full absolute rounded-full theme-icon",
  );

  return (
    <div
      className={wrapper}
      key={theme}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => {
        Posthog.capture(TelemetryEvents.onboardingThemePickerSelectTheme, {
          theme,
        });
        onThemeChange(theme);
      }}
    >
      <AnimatePresence>
        {currentTheme === theme && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            className="absolute z-10 flex h-1/2 w-1/2 items-center justify-center rounded-full bg-white shadow-md"
          >
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="drop-shadow-sm/50 text-blue-500"
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div key={theme} className={themeIconClasses} />
      <Transition
        as={Fragment}
        show={hover}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute -top-5 z-20 rounded-lg bg-white p-2 text-xs capitalize shadow-lg dark:bg-neutral-700 dark:text-white">
          {theme}
        </div>
      </Transition>
    </div>
  );
};

export const ThemeSelector = () => {
  const { user, updateUser } = useOnboarding();

  return (
    <div className="mt-4 grid grid-cols-4 items-center justify-items-center gap-4">
      {Object.entries(themeCollection)
        .filter(([_, { userConfigurable }]) => userConfigurable)
        .map(([themeName]) => (
          <ThemeIcon
            currentTheme={user.theme}
            theme={themeName as ThemeName}
            key={themeName}
            onThemeChange={(theme: ThemeName) => {
              updateUser({ theme });
            }}
          />
        ))}
    </div>
  );
};
