import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLifeRing } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { CobrandingLogo, Tooltip } from "@tigris/mesokit";
import { CountryCodeAlpha2, Routes } from "../types";
import { useRouter } from "../hooks/useRouter";
import { useOnboarding } from "../hooks/useOnboarding";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

const MENU_ITEM_LINK_CLASS =
  "group flex dark:text-white cursor-pointer flex-row items-center rounded-full py-2 pl-3 pr-4 opacity-75 font-medium transition-all duration-150 hover:bg-neutral-100 hover:opacity-100 hover:dark:bg-neutral-800";

const currentUSStepMapping = new Map<string, number>([
  [Routes.CreateAccount, 2],
  [Routes.PhoneEntry, 3],
  [Routes.RegisterPasskey, 4],
  [Routes.BasicInfoOverview, 4],
  [Routes.BasicInfoEntry, 5],
  [Routes.TaxpayerIdEntry, 6],
  [Routes.ScamWarning, 7],
  [Routes.SelectPaymentMethod, 8],
  [Routes.ApplePayScamWarning, 8],
  [Routes.AddPaymentCard, 8],
  [Routes.Summary, 9],
]);

const internationalCurrentStepMapping = new Map<string, number>([
  [Routes.CreateAccount, 2],
  [Routes.PhoneEntry, 3],
  [Routes.RegisterPasskey, 4],
  [Routes.BasicInfoOverview, 4],
  [Routes.PersonalInfo, 5],
  [Routes.Pesel, 5],
  [Routes.ResidentialAddress, 6],
  [Routes.ScamWarning, 7],
  [Routes.SelectPaymentMethod, 8],
  [Routes.ApplePayScamWarning, 8],
  [Routes.AddPaymentCard, 8],
  [Routes.Summary, 9],
]);

const showCobrandingRoutes = Array.from(
  new Set([
    ...currentUSStepMapping.keys(),
    ...internationalCurrentStepMapping.keys(),
    Routes.DeclareResidency,
    Routes.HandoffFailed,
    Routes.ManualReview,
  ]),
);

export const Navigation = () => {
  const { currentRoute } = useRouter();
  const {
    configuration: { partner },
    user,
    cookieConsent: { showCookieDialog },
  } = useOnboarding();

  const mapping =
    user.residenceCountry === CountryCodeAlpha2.US
      ? currentUSStepMapping
      : internationalCurrentStepMapping;
  const currentStep = mapping.get(currentRoute.pathname) || 0;
  const totalSteps = mapping.size;
  const width = (currentStep / totalSteps) * 100 + "%";
  const progressComplete = currentStep === totalSteps;

  return (
    <AnimatePresence mode="wait">
      <nav className="flex h-10 flex-col justify-between gap-1">
        <div className="relative flex w-full items-center dark:text-white">
          <AnimatePresence>
            {showCobrandingRoutes.includes(currentRoute.pathname) && (
              <motion.div layoutId="CoBranding">
                <CobrandingLogo
                  displayName={partner?.displayName}
                  logoUri={partner?.logoUri}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <Popover className="relative ml-auto">
            {({ open }) => (
              <Tooltip message="Get Help" suppress={open}>
                <>
                  <PopoverButton
                    className={twMerge(
                      "size-6 rounded-full transition-colors outline-none",
                      open && "bg-primary-light text-white",
                    )}
                  >
                    <FontAwesomeIcon
                      icon={faLifeRing}
                      className={twMerge(
                        "cursor-pointer items-center gap-1 opacity-60 transition-all hover:rotate-90 hover:opacity-100",
                        open && "rotate-90 opacity-100",
                      )}
                    />
                  </PopoverButton>
                  <PopoverPanel
                    anchor="bottom"
                    transition
                    className="relative z-50 mt-2 flex origin-top flex-col transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
                  >
                    <div className="flex w-full flex-col gap-2 rounded-3xl bg-white p-2 md:max-w-sm dark:bg-neutral-700 dark:shadow-lg">
                      <a
                        href="mailto:support@meso.network"
                        className={MENU_ITEM_LINK_CLASS}
                      >
                        <span className="transition-all group-hover:translate-x-1">
                          Email Support
                        </span>
                      </a>
                      <a
                        href="https://support.meso.network"
                        target="_blank"
                        rel="noreferrer"
                        className={MENU_ITEM_LINK_CLASS}
                      >
                        <span className="transition-all group-hover:translate-x-1">
                          Support Center
                        </span>
                      </a>
                      <div
                        className={MENU_ITEM_LINK_CLASS}
                        onClick={() => showCookieDialog("preferences")}
                      >
                        <span className="transition-all group-hover:translate-x-1">
                          Privacy Preferences
                        </span>
                      </div>
                    </div>
                  </PopoverPanel>
                </>
              </Tooltip>
            )}
          </Popover>
        </div>
        <div className="relative w-full">
          <div className="h-[2px] overflow-hidden rounded-sm bg-neutral-200/50 dark:bg-neutral-700">
            <motion.div
              layout
              initial={{ width: "0%", opacity: 0 }}
              animate={{
                width,
                opacity: width === "0%" ? 0 : 1,
              }}
              transition={{ ease: "anticipate", duration: 1 }}
              className="bg-highlight h-full"
            />
          </div>
          <AnimatePresence>
            {progressComplete && (
              <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  rotate: -12,
                  transition: { delay: 0.5 },
                }}
                exit={{ scale: 0.5, opacity: 0 }}
                className="absolute -top-3 right-0 rounded-full bg-neutral-100 dark:bg-neutral-800"
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-violet-400"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>
    </AnimatePresence>
  );
};
