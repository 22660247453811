import { motion, Variants } from "framer-motion";
import { PropsWithChildren } from "react";
import { spring } from "../utils/animation";

type AnimationContainerProps = {
  /** An optional callback for when the wrapped animation is complete. */
  onAnimationComplete?: () => void;
};

const variants: Record<string, Variants> = {
  fadeInOut: {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.25, ...spring } },
  },
};

const AnimationContainer = ({
  children,
  onAnimationComplete = () => {},
}: PropsWithChildren<AnimationContainerProps>) => {
  return (
    <motion.div
      variants={variants.fadeInOut}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="flex h-full flex-grow flex-col"
      onAnimationComplete={(completedAnimationName) => {
        if (completedAnimationName === "visible") {
          onAnimationComplete();
        }
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimationContainer;
