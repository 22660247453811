import { usePasskey } from "../hooks/usePasskey";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { Button, spring } from "@tigris/mesokit";
import { useState, useCallback, FormEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint } from "@fortawesome/pro-light-svg-icons";
import { motion } from "framer-motion";
import { toast } from "sonner";
import { useRouter } from "../hooks/useRouter";
import { Routes } from "../types";
import { Posthog, TelemetryEvents } from "@tigris/common";

const FORM_ID = "RegisterPasskey";
const TOAST_ID = FORM_ID;

export const RegisterPasskey = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { navigate } = useRouter();
  const { registerPasskey } = usePasskey();

  const handleRegisterPasskey = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      setIsLoading(true);
      (async () => {
        const result = await registerPasskey();
        if (result.isErr()) {
          toast.error(result.error, { id: TOAST_ID });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          navigate(Routes.BasicInfoOverview);
        }
      })();
    },
    [registerPasskey, navigate],
  );

  return (
    <AnimationContainer>
      <form
        id={FORM_ID}
        name={FORM_ID}
        onSubmit={handleRegisterPasskey}
        className="onboarding-inner-content"
      >
        <Heading
          title="Setup a Passkey"
          subtitle="Use a device-based passkey for quick secure access to your Meso account."
        />
        <div className="flex h-full w-full flex-col items-center justify-center">
          <motion.div
            className="flex h-auto w-full flex-col items-center gap-6 rounded-2xl border border-neutral-100 p-4 py-12 shadow-xl dark:border-neutral-700"
            initial={{ opacity: 0, y: 22 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { ...spring, delay: 0.5 },
            }}
          >
            <FontAwesomeIcon
              icon={faFingerprint}
              className="text-primary dark:text-primary-light h-16 w-16"
            />
            <div className="text-center text-sm text-neutral-800 dark:text-white">
              <span>Passkeys are a secure and simple way to sign in. </span>
              <a
                href="https://support.meso.network/hc/en-us/articles/16963973955355-Account-Login"
                target="_blank"
                rel="noreferrer"
                className="text-primary dark:text-primary-light leading-none font-semibold"
              >
                Learn more →
              </a>
            </div>
          </motion.div>
        </div>

        <div className="onboarding-footer mt-auto">
          <button
            onClick={(e) => {
              e.preventDefault();
              Posthog.capture(TelemetryEvents.passkeyRegistrationSkip);
              navigate(Routes.BasicInfoOverview);
            }}
            className="mb-2 w-full py-3 text-center font-semibold text-neutral-800 dark:text-neutral-300"
            data-testid="OnboardingRegisterPasskey:skip"
          >
            Do this later
          </button>

          <Button
            key="OnboardingRegisterPasskey:button"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Setup Passkey
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
