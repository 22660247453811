import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, FormEventHandler, useCallback, useState } from "react";
import { Text, Button, spring, LoadingInterstitial } from "@tigris/mesokit";
import {
  faLightEmergencyOn,
  faCircle1,
  faCircle2,
  faCircle3,
} from "@fortawesome/pro-solid-svg-icons";
import { toast } from "sonner";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { useRouter } from "../hooks/useRouter";
import { ScamWarningKind } from "../generated/sdk";
import { Posthog, TelemetryEvents } from "@tigris/common";
import { Routes, UserStatus } from "../types";
import { useActivateUser } from "../hooks/useActivateUser";

const TOAST_ID = "Apple Pay Scam Warning";
const FLAG_ICON_CLASS = "w-6 w-6 pr-1 text-danger";

export const ApplePayScamWarning = () => {
  const { navigate, enableNavigation, disableNavigation } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadingInterstitial, setShowLoadingInterstitial] = useState(false);
  const { returnToTransfer } = useOnboarding();
  const { activateUser, isActivatingUser } = useActivateUser();
  const {
    api: { resolveAcknowledgeScamWarning },
    session,
  } = useApi();

  const handleActivateUserError = useCallback(
    (userActivationStartTime: number) => {
      // Collect metrics
      const duration = performance.now() - userActivationStartTime;
      const sessionId = session?.id;
      Posthog.capture(TelemetryEvents.userActivationComplete, {
        status: "error",
        sessionId,
        duration,
      });

      enableNavigation();

      returnToTransfer("onboardingTerminated");
    },
    [enableNavigation, returnToTransfer, session?.id],
  );

  const handleActivateUserSuccess = useCallback(
    (userStatus: UserStatus, userActivationStartTime: number) => {
      // Collect metrics
      const duration = performance.now() - userActivationStartTime;
      const sessionId = session?.id;

      Posthog.capture(TelemetryEvents.userActivationComplete, {
        status: userStatus,
        sessionId,
        duration,
      });

      enableNavigation();

      if (userStatus === UserStatus.ACTIVE) {
        navigate(Routes.Summary);
      } else if (userStatus === UserStatus.IN_REVIEW) {
        // Routing to /manual-review will handle the `returnToTransfer` call
        navigate(Routes.ManualReview, { userStatus });
      } else {
        returnToTransfer("onboardingTerminated");
      }
    },
    [enableNavigation, navigate, returnToTransfer, session?.id],
  );

  const handleAcknowledgeApplePayScamWarning = useCallback<FormEventHandler>(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);

      const acknowledgeScamWarningResult = await resolveAcknowledgeScamWarning({
        input: { kind: ScamWarningKind.APPLE_PAY },
      });

      if (acknowledgeScamWarningResult.isErr()) {
        toast.error(acknowledgeScamWarningResult.error, { id: TOAST_ID });
        setIsLoading(false);
        return;
      }

      Posthog.capture(TelemetryEvents.onboardingApplePayScamWarning);

      // The user has completed all profile steps
      const userActivationStartTime = performance.now();

      Posthog.capture(TelemetryEvents.userActivationStart, {
        sessionId: session?.id,
      });

      setShowLoadingInterstitial(true);
      disableNavigation();

      const activateUserResult = await activateUser();
      if (activateUserResult.isErr()) {
        handleActivateUserError(userActivationStartTime);
      } else {
        handleActivateUserSuccess(
          activateUserResult.value,
          userActivationStartTime,
        );
      }
    },
    [
      disableNavigation,
      handleActivateUserError,
      handleActivateUserSuccess,
      resolveAcknowledgeScamWarning,
      session?.id,
      activateUser,
    ],
  );

  return (
    <AnimationContainer>
      {showLoadingInterstitial ? (
        <LoadingInterstitial mode="loading" />
      ) : (
        <form
          id="OnboardingApplePayScamWarning"
          name="OnboardingApplePayScamWarning"
          onSubmit={handleAcknowledgeApplePayScamWarning}
          className="onboarding-inner-content"
        >
          <Heading
            title="Protect Yourself from Scams"
            subtitle="Never trust unsolicited requests to buy and send crypto."
          />

          <div className="flex w-full flex-col gap-2 rounded-xl border border-solid border-neutral-800/5 p-4 shadow-md dark:border-gray-600">
            <div className="flex flex-col gap-1.5 border-b border-solid border-neutral-800/5 pb-2 dark:border-gray-600">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faCircle1} className={FLAG_ICON_CLASS} />
                <Text className="font-bold">Meso Is For Buying Crypto</Text>
              </div>
              <Text className="opacity-70">
                Transfers are for buying crypto, not for reversing Apple or
                PayPal charges.
              </Text>
            </div>
            <div className="flex flex-col gap-1.5 border-b border-solid border-neutral-800/5 pb-2 dark:border-gray-600">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faCircle2} className={FLAG_ICON_CLASS} />
                <Text className="font-bold">
                  Meso Is Not Used for Tech Support
                </Text>
              </div>
              <Text className="opacity-70">
                If someone from &quot;tech support&quot; asks you to use Meso,
                stop. This is a scam.
              </Text>
            </div>
            <div className="flex flex-col gap-1.5">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faCircle3} className={FLAG_ICON_CLASS} />
                <Text className="font-bold">Crypto Transfers Are Final</Text>
              </div>
              <Text className="opacity-70">
                Once you make a transfer, you cannot get it back - even if you
                sent it to a scammer.
              </Text>
            </div>
          </div>

          <AnimatePresence>
            <motion.div
              className="mt-auto flex h-auto w-full items-center gap-2 rounded-2xl border border-neutral-100 p-4 shadow-xl dark:border-neutral-700"
              initial={{ opacity: 0, y: 24 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { ...spring, delay: 0.5 },
              }}
            >
              <FontAwesomeIcon
                icon={faLightEmergencyOn}
                size="lg"
                className="text-warning"
              />
              <div>
                <Text className="font-bold">Do you want to proceed? </Text>
                <Text className="text-xs text-neutral-800 dark:text-white">
                  If in doubt, contact Meso support for help at{" "}
                  <a href="mailto:support@meso.network">support@meso.network</a>
                  .
                </Text>
              </div>
            </motion.div>
          </AnimatePresence>

          <div className="onboarding-footer mt-0">
            <Button
              disabled={isLoading || isActivatingUser}
              key="OnboardingApplePayScamWarning:button"
              type="submit"
            >
              Continue
            </Button>
          </div>
        </form>
      )}
    </AnimationContainer>
  );
};
