import { motion, spring, easeOut } from "framer-motion";
import { PropsWithChildren } from "react";
import { LogoLoader } from "./LogoLoader";
import { twMerge } from "tailwind-merge";

const LOADING_INTERSTITIAL_ANIMATION_Y_POS = 16;

type LoadingInterstitialProps = {
  /**
   * When in `waiting` mode, the provided children (usually a contextual message) will be rendered.
   * When in `loading` mode, the Meso logo will animate to the center and "hover" until dismissed
   */
  mode: "waiting" | "loading";
  /* Determines positioning of interstitial. Defaults to absolute */
  position?: "absolute" | "static";
};

export const LoadingInterstitial = ({
  children,
  mode,
  position = "absolute",
}: PropsWithChildren<LoadingInterstitialProps>) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      className={twMerge(
        position,
        position === "absolute" &&
          "top-0 left-0 z-8888 h-full w-full px-6 py-4",
      )}
      key="LoadingInterstitial"
    >
      <div
        data-testid="LoadingInterstitial"
        key="LoadingInterstitial"
        className="flex h-full flex-col items-center justify-center text-center"
        style={{ perspective: "1000px" }}
      >
        <motion.div
          key="LoadingInterstitial:logo"
          initial={{
            y: -LOADING_INTERSTITIAL_ANIMATION_Y_POS,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition:
              mode === "waiting"
                ? { ...spring }
                : { ...easeOut, duration: 0.7 },
          }}
          exit={{
            y: -LOADING_INTERSTITIAL_ANIMATION_Y_POS,
            opacity: 0,
            transition: { ...spring },
          }}
        >
          <LogoLoader />
        </motion.div>

        {mode === "waiting" ? (
          <motion.div
            key="LoadingInterstitial:waiting"
            initial={{
              y: LOADING_INTERSTITIAL_ANIMATION_Y_POS,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              filter: "blur(0px)",
              rotateX: 0,
              scaleX: 1,
              transition: { ...spring },
              height: "auto",
            }}
            exit={{
              y: -LOADING_INTERSTITIAL_ANIMATION_Y_POS,
              opacity: 0,
              transition: { delay: 0.15 },
            }}
          >
            {children}
          </motion.div>
        ) : (
          <motion.div
            key="LoadingInterstitial:loading"
            initial={{
              y: LOADING_INTERSTITIAL_ANIMATION_Y_POS,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              filter: "blur(16px)",
              rotateX: 90,
              scaleX: 0.35,
              transition: { type: "spring", bounce: 0.2, duration: 0.8 },
              height: "15px",
            }}
            exit={{
              y: -LOADING_INTERSTITIAL_ANIMATION_Y_POS,
              opacity: 0,
              transition: { delay: 0.15 },
            }}
          >
            <div className="block h-24 w-24 rounded-full bg-black opacity-10 shadow-xl shadow-black" />
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};
