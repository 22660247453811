import {
  AppContextValue,
  ExtractedSardineContext,
  MessageKind,
} from "@src/types";
import { PropsWithChildren, useContext, useEffect, useMemo } from "react";
import {
  SardineProvider,
  SardineContext,
  SardineEnvironment,
} from "@sardine-ai/react-js-wrapper";
import { AppContext } from "@src/contexts/AppContext";
import { isTransferRoute } from "@src/utils/routeHelpers";
import { useLocation } from "react-router-dom";
import { Sentry } from "@tigris/common";

export const SardineRiskSession = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();
  const {
    session,
    updateSession,
    api: { resolveRiskSession },
    bus,
  } = useContext(AppContext);

  const activeSardineFlow = useMemo(() => {
    if (isTransferRoute(pathname)) {
      return "transfer";
      // In standalone, when we embed the Onboarding app as a component, it uses the transfer-app's sardine session.
      // For this case, set the flow to `onboarding`.
    } else if (pathname.startsWith("/onboarding")) {
      return "onboarding";
    } else {
      return undefined;
    }
  }, [pathname]);

  useEffect(() => {
    async function fn() {
      if (activeSardineFlow === undefined) return;

      const riskSessionResult = await resolveRiskSession();

      if (riskSessionResult.isErr()) {
        bus?.emit({
          kind: MessageKind.ERROR,
          payload: { message: riskSessionResult.error },
        });
      } else {
        updateSession({
          riskSession: {
            ...riskSessionResult.value,
            environment: riskSessionResult.value
              .environment as SardineEnvironment,
          },
        });
      }
    }

    fn().catch((err) => {
      Sentry.captureException(err);
      bus?.emit({
        kind: MessageKind.ERROR,
        payload: { message: "Unable to initialize the Meso transfer flow." },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSardineFlow]);

  return (
    <SardineProvider
      clientId={session ? session.riskSession.clientId : ""}
      environment={session ? session.riskSession.environment : "sandbox"}
    >
      <SardineConfig flow={activeSardineFlow} session={session}>
        {children}
      </SardineConfig>
    </SardineProvider>
  );
};

function SardineConfig({
  children,
  flow,
  session,
}: PropsWithChildren<{
  flow?: "transfer" | "onboarding";
  session: AppContextValue["session"];
}>) {
  const { setupSardineWithConfig } = useContext(
    SardineContext as React.Context<ExtractedSardineContext>,
  );

  useEffect(() => {
    if (!session) return;

    setupSardineWithConfig({
      customerId: session.riskSession.userId,
      sessionKey: session.riskSession.sessionKey,
      flow,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow, session]);

  return <>{children}</>;
}
