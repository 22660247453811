import { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Navigator } from "./Navigator";
import { PostMessageLogger } from "./PostMessageLogger";
import { PostMessageLoggerContextProvider } from "./contexts/PostMessageLoggerContextProvider";
import { useRouter } from "../hooks/useRouter";
import { Routes } from "../types";
import { User } from "./User";
import { FeatureFlags } from "./FeatureFlags";
import { PeselHelper } from "./PeselHelper";
import { AnimatePresence } from "framer-motion";
import { AutoSignupHelper } from "./AutoSignupHelper";
import { PhoneNumberHelper } from "./PhoneNumberHelper";
import { useOnboarding } from "../hooks/useOnboarding";
import { AddPaymentCardHelper } from "./AddPaymentCardHelper";

const views = {
  Navigator: <Navigator />,
  Logger: <PostMessageLogger />,
  User: <User />,
  Flags: <FeatureFlags />,
};

export const DevControls = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { currentRoute } = useRouter();
  const [currentView, setCurrentView] =
    useState<keyof typeof views>("Navigator");
  const [isLightMode, setIsLightMode] = useState(
    () => !document.documentElement.classList.contains("dark-mode"),
  );
  const { user } = useOnboarding();

  useEffect(() => {
    if (isLightMode) {
      document.documentElement.classList.remove("dark-mode");
    } else {
      document.documentElement.classList.add("dark-mode");
    }
  }, [isLightMode]);

  // This statement prevents this entire module from being bundled in our production builds
  if (!(import.meta.env.VITE_TIGRIS_ENV === "preview" || import.meta.env.DEV)) {
    return;
  }

  // this is okay because this will only be rendered in dev/preview
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const toggleCollapsed = useCallback(
    () => setCollapsed(!collapsed),
    [collapsed],
  );

  return (
    <div
      className={twMerge(
        "fixed right-2 bottom-2 z-10 flex w-[320px] flex-col overflow-hidden rounded-sm lg:right-0 lg:bottom-0",
        "h-[480px] lg:h-full",
        "bg-neutral-200 dark:bg-black/40",
        "font-mono text-neutral-800/40 dark:text-neutral-200/80",
        collapsed &&
          "!right-2 !bottom-2 !h-[32px] w-[32px] rounded-full bg-black/10 dark:bg-white/10",
      )}
    >
      {collapsed ? (
        <div
          className={twMerge(
            "flex h-full w-full cursor-pointer items-center justify-center",
            "transition-all",
            "hover:bg-purple-900 hover:text-white",
          )}
          onClick={toggleCollapsed}
        >
          <div className="w-[18px]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z" />
            </svg>
          </div>
        </div>
      ) : (
        <>
          <header
            className={twMerge(
              "px-2 py-4",
              "text-xs font-bold text-neutral-500 dark:text-white",
              "bg-neutral-300 dark:bg-black",
              "border-b border-b-neutral-400 dark:border-b-neutral-800",
            )}
          >
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="size-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.5 8a3.5 3.5 0 0 0 3.362-4.476c-.094-.325-.497-.39-.736-.15L12.099 5.4a.48.48 0 0 1-.653.033 8.554 8.554 0 0 1-.879-.879.48.48 0 0 1 .033-.653l2.027-2.028c.24-.239.175-.642-.15-.736a3.502 3.502 0 0 0-4.476 3.427c.018.99-.133 2.093-.914 2.7l-5.31 4.13a2.015 2.015 0 1 0 2.828 2.827l4.13-5.309c.607-.78 1.71-.932 2.7-.914L11.5 8ZM3 13.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                Meso dev tools
              </div>

              <div className="flex items-center gap-3">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setIsLightMode(!isLightMode);
                  }}
                >
                  {isLightMode ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      fill="currentColor"
                      className="size-4"
                    >
                      <path d="M190.6 66.8c-38.8 37.8-62.9 90.7-62.9 149.2c0 108.9 83.5 198.3 189.9 207.3C289.8 439 257.7 448 223.5 448C117.7 448 32 362.1 32 256c0-94.8 68.5-173.5 158.6-189.2zm66.1-21.5c-1.5-6.9-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="currentColor"
                      className="size-4"
                    >
                      <path d="M256 0c8.8 0 16 7.2 16 16l0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80c0-8.8 7.2-16 16-16zM0 256c0-8.8 7.2-16 16-16l80 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-80 0c-8.8 0-16-7.2-16-16zm400 0c0-8.8 7.2-16 16-16l80 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-80 0c-8.8 0-16-7.2-16-16zM256 400c8.8 0 16 7.2 16 16l0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80c0-8.8 7.2-16 16-16zM75 75c6.2-6.2 16.4-6.2 22.6 0l56.6 56.6c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L75 97.6c-6.2-6.2-6.2-16.4 0-22.6zm0 362c-6.2-6.2-6.2-16.4 0-22.6l56.6-56.6c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L97.6 437c-6.2 6.2-16.4 6.2-22.6 0zM357.8 154.2c-6.2-6.2-6.2-16.4 0-22.6L414.4 75c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-56.6 56.6c-6.2 6.2-16.4 6.2-22.6 0zm0 203.6c6.2-6.2 16.4-6.2 22.6 0L437 414.4c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-56.6-56.6c-6.2-6.2-6.2-16.4 0-22.6zM336 256a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zm-192 0a112 112 0 1 1 224 0 112 112 0 1 1 -224 0z" />
                    </svg>
                  )}
                </div>
                <div onClick={toggleCollapsed} className="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="size-5"
                  >
                    <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                  </svg>
                </div>
              </div>
            </div>
          </header>

          {/* Nav */}
          <div className="flex items-center gap-2 overflow-hidden p-4">
            <div className="flex w-full justify-between">
              {Object.keys(views).map((viewName) => (
                <div
                  key={viewName}
                  className={twMerge(
                    "w-full py-2",
                    "text-center text-xs font-bold",
                    "cursor-pointer",
                    currentView === viewName &&
                      "rounded-lg bg-purple-900 text-white opacity-100 dark:bg-purple-900/40",
                  )}
                  onClick={setCurrentView.bind(
                    this,
                    viewName as keyof typeof views,
                  )}
                >
                  {viewName}
                </div>
              ))}
            </div>
          </div>

          {/* Content */}
          <div className="flex flex-auto flex-col gap-4 overflow-auto p-3">
            {/* Outlet content */}
            <div className="grow px-4 py-2" id="foo">
              <PostMessageLoggerContextProvider>
                {views[currentView]}
              </PostMessageLoggerContextProvider>
            </div>
          </div>

          <AnimatePresence mode="wait">
            {currentRoute.pathname === Routes.Root &&
              currentView === "Navigator" && <AutoSignupHelper />}
          </AnimatePresence>

          <AnimatePresence mode="wait">
            {currentRoute.pathname === Routes.Pesel && <PeselHelper />}
          </AnimatePresence>

          <AnimatePresence mode="wait">
            {currentRoute.pathname === Routes.PhoneEntry && (
              <PhoneNumberHelper countryCodeAlpha2={user.residenceCountry} />
            )}
          </AnimatePresence>

          <AnimatePresence mode="wait">
            {currentRoute.pathname === Routes.AddPaymentCard && (
              <AddPaymentCardHelper countryCodeAlpha2={user.residenceCountry} />
            )}
          </AnimatePresence>
        </>
      )}
    </div>
  );
};
